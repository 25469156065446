import React from "react";
import CommonListBase from "./common-list-base";
import { Button, message } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FileImageOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import PortalUser from "../iam/components/portal-user";
import {
  dateFormatter,
  currencyFormatter,
  resDate,
} from "../components/common/common-formatter";
import { ajaxRequester } from "../services/ajax-requester";
import * as dayjs from "dayjs";
import moment from "moment";

class OperationsBase extends CommonListBase {
  state = {};

  getPage = () => {
    let page = this.props.match.path.replace("/operations/", "");
    let title = "";
    let listPath = "";
    let listPathToggle = "";

    switch (page) {
      case "waiting-for-flex":
        title = "Waiting For Flex";
        listPath = "operation/waitingforflex/list?";
        listPathToggle = "operation/flexreceived/history?";
        break;

      case "change-of-site":
        title = "Change of Site List";
        listPath = "operation/changeofsite/list?";
        listPathToggle = "operation/changeofsite/list?showHistory=true&";
        break;

      case "pending-mounting":
        title = "Mounting";
        listPath = "operation/pendingmounting/list?";
        listPathToggle = "operation/mounting/history?";
        break;

      case "pending-unmounting-photo":
        title = "Unmounting Photo";
        listPath = "operation/pendingunmountingphoto/list?";
        listPathToggle = "operation/unmountinngphoto/history?";
        break;

      case "pending-unmounting":
        title = "Unmounted Flex Receive";
        listPath = "operation/pendingunmountingflex/list?";
        listPathToggle = "operation/unmountingflex/history?";
        break;

      case "monitoring":
        title = "Monitoring";
        listPath = "operation/pendingmonitoring/list?";
        listPathToggle = "operation/monitoring/history?";
        break;

      case "mounting-unmounting":
        title = "Advanced Mounting / Unmounting";
        listPath = "operation/advancedpendingmountingunmounting/list?";
        listPathToggle = "operation/advancedmountingunmounting/history?";
        break;

      case "stock-management":
        title = "Flex Stock Management";
        listPath = "operation/stockonhand/list?";
        break;

      case "campaign-operations/:id":
        page = "campaign-operations";
        title = "Operations";
        listPath =
          "campaign/waitingforflex/list?campaign_id=" +
          this.props.match.params.id +
          "&";
        break;

      default:
        break;
    }

    // Get List
    const { pagination } = this.state;

    this.setState(
      {
        page,
        title,
        listReq: listPath,
        listPath,
        listPathToggle,
        screen: page,
      },
      () => (this.getResults({ pagination }), this.setColumns())
    );
  };

  setColumns = () => {
    let page = this.props.match.path.replace("/operations/", "");
    let isHistory = this.state?.listReq?.includes("history");
    let screen = this.state?.screen;

    let columns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "State",
        dataIndex: "state",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text +
          (record?.quantity && record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 98,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Creative Name",
        dataIndex: "creative_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 130,
      },
      {
        title: "Monitoring Name",
        dataIndex: "monitoring_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Site Start Date",
        dataIndex: "start_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 128,
      },
      {
        title: "Site End Date",
        dataIndex: "end_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 124,
      },
      {
        title: "Operation Status",
        dataIndex: "operation_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 160,
      },
      {
        title: "Search Tags",
        dataIndex: "search_tags",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
    ];

    let filterColumns = [
      {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "state", displayName: "State" },
      { columnName: "district", displayName: "District" },
      { columnName: "city", displayName: "City" },
      { columnName: "area", displayName: "Area" },
      { columnName: "location", displayName: "Location" },
      { columnName: "size", displayName: "Size" },
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "employee_id", displayName: "Employee" },
      { columnName: "customer_name", displayName: "Customer Name" },
      { columnName: "display_name", displayName: "Display" },
      { columnName: "creative_name", displayName: "Creative Name" },
      { columnName: "monitoring_name", displayName: "Monitoring Name" },
      { columnName: "start_date", displayName: "Site Start Date" },
      { columnName: "end_date", displayName: "Site End Date" },
      { columnName: "operation_status", displayName: "Operation Status" },
      {
        columnName: "search_tags",
        displayName: "Search Tags",
        hideFilter: true,
      },
    ];

    let col = {
      cIID: {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cState: {
        title: "State",
        dataIndex: "state",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cDistrict: {
        title: "District",
        dataIndex: "district",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cCity: {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cArea: {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cLocation: {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text +
          (record?.quantity && record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      cSize: {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cProjectId: {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 98,
      },
      cEmployee: {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      cCustomerName: {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      cDisplay: {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      cCreativeName: {
        title: "Creative Name",
        dataIndex: "creative_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 130,
      },
      cMonitoringName: {
        title: "Monitoring Name",
        dataIndex: "monitoring_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      cSiteStartDate: {
        title: "Site Start Date",
        dataIndex: "start_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 128,
      },
      cSiteEndDate: {
        title: "Site End Date",
        dataIndex: "end_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 124,
      },
      cOperationStatus: {
        title: "Operation Status",
        dataIndex: "operation_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 160,
      },
      cSupplier: {
        title: "Supplier",
        dataIndex: "supplier_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cSearchTags: {
        title: "Search Tags",
        dataIndex: "search_tags",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cPlannedMountingDate: {
        title: "Planned Mounting Date",
        dataIndex: "installation_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 186,
      },
      cFlexReceivedDate: {
        title: "Flex Received Date",
        dataIndex: "flex_received_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 182,
      },
      cAssignedMounter: {
        title: "Assigned Mounter",
        dataIndex: "assigned_mounter_id",
        render: (text) => text && <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 160,
      },
      cBookingStatus: {
        title: "Booking Status",
        dataIndex: "booking_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 148,
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            type="link"
            onClick={() => this.statusInventory(record, "booking")}
          >
            {text === "B"
              ? "Blocked"
              : text === "N"
              ? "Not Available"
              : "Available"}
          </Button>
        ),
      },
      cMountingDelayedBy: {
        title: "Mounting Delayed By",
        dataIndex: "mounting_delayed_by",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 180,
      },
      cActualMountingDate: {
        title: "Actual Mounting Date",
        dataIndex: "mounting_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 178,
      },
      cLossDuetoDelayed: {
        title: "Loss Due to Delayed",
        dataIndex: "loss_due_to_delayed_mounting",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 168,
        render: (text) => currencyFormatter(parseInt(text)),
      },
      cMountedPhoto: {
        title: "Mounted Photo",
        dataIndex: "mounting_photos",
        width: 142,
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.mounting_photos?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record, "mounting_photos")}
          />
        ),
      },
      cPlannedMonitoringDate: {
        title: "Planned Monitoring Date",
        dataIndex: "monitoring_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 200,
      },
      cMonitoringType: {
        title: "Monitoring Type",
        dataIndex: "monitoring_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 142,
      },
      cMonitoringStatus: {
        title: "Monitoring Status",
        dataIndex: "monitoring_done",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 162,
        render: (text) => (text ? "Done" : "Pending"),
      },
      cActualMonitoringDate: {
        title: "Actual Monitoring Date",
        dataIndex: "monitoring_performed_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 210,
      },
      cMonitoringDelayedBy: {
        title: "Monitoring Delayed By",
        dataIndex: "monitoring_delayed_by",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 190,
      },
      cPerformedBy: {
        title: "Performed By",
        dataIndex: "monitoring_by",
        render: (text) => text && <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 160,
      },
      cMonitoringPhoto: {
        title: "Monitoring Photo",
        dataIndex: "monitoring_photos",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 148,
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.monitoring_photos?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record, "monitoring_photos")}
          />
        ),
      },
      cNextDisplay: {
        title: "Next Display",
        dataIndex: "next_display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      cAssignedUnmounter: {
        title: "Assigned Unmounter",
        dataIndex: "assigned_unmounter_id",
        render: (text) => text && <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 168,
      },
      cUnmountingPhotoDelayedBy: {
        title: "Unmounting Photo Delayed By",
        dataIndex: "unmounting_delayed_by",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 180,
      },
      cUnmountedFlexDelayedBy: {
        title: "Unmounted Flex Delayed By",
        dataIndex: "unmounting_flex_delayed_by",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cActualUnmountingPhotoDate: {
        title: "Actual Unmounting Photo Date",
        dataIndex: "unmounting_photo_performed_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cUnmountingPhotoBy: {
        title: "Unmounting Photo By",
        dataIndex: "unmounting_photo_by",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cUnmountedPhoto: {
        title: "Unmounted Photo",
        dataIndex: "unmounting_photos",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 142,
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.unmounting_photos?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record, "unmounting_photos")}
          />
        ),
      },
      cBranch: {
        title: "Branch",
        dataIndex: "branch_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cSQFT: {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cWeightinKG: {
        title: "Weight in KG",
        dataIndex: "flex_weight",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 150,
      },
      cUnmountedFlexReceivedDate: {
        title: "Unmounted Flex Received Date",
        dataIndex: "unmounted_flex_received_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 220,
      },
      cUnmountingDelayedBy: {
        title: "Unmounting Delayed By",
        dataIndex: "unmounting_delayed_by",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 190,
      },
      cAdvantageExploitedByClient: {
        title: "Advantage Exploited By Client",
        dataIndex: "advantage_exploited_by_client",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 240,
      },
      cUnmountingDoneBy: {
        title: "Unmounting Done By",
        dataIndex: "flex_unmounted_by",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 180,
      },
      cUnmountedPhotoStock: {
        title: "Unmounted Photo",
        dataIndex: "unmounted_photo",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 180,
      },
      cFlexReceivedDateCampaign: {
        title: "Flex Received Date",
        dataIndex: "flex_received_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 168,
      },
      cMedia: {
        title: "Media",
        dataIndex: "media_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cQty: {
        title: "Qty",
        dataIndex: "quantity",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cLight: {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cUnmountingPhotoDate: {
        title: "Unmounting Photo Date",
        dataIndex: "unmounting_photo_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cUnmountingFlexReceivedOn: {
        title: "Unmounting Flex Received On",
        dataIndex: "unmounted_flex_received_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cPlannedUnmountingPhotoDate: {
        title: "Planned Unmounting Photo Date",
        dataIndex: "unmounting_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cActualUnmountingPhotoPerformedDate: {
        title: "Actual Unmounting Photo Performed Date",
        dataIndex: "unmounting_photo_performed_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cUnmountedFlexReceiveDate: {
        title: "Unmounted Flex Receive Date",
        dataIndex: "unmounted_flex_received_date",
        render: (text) =>
          text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      cPrintingCost: {
        title: "Printing Cost",
        dataIndex: "printing_cost",
        width: 102,
      },
      cInstallingCost: {
        title: "Mounting Cost",
        dataIndex: "installing_cost",
        width: 116,
      },
      cMountingActions: {
        title: "Actions",
        dataIndex: "",
        render: (record) => (
          <Button
            onClick={() =>
              this.handleAction("pending-mounting", "edit-mounting", record)
            }
            icon={<EditOutlined />}
            className="ant-btn-reset"
          />
        ),
      },
      cMonitoringAction: {
        title: "Actions",
        dataIndex: "",
        render: (record) => (
          <Button
            onClick={() =>
              this.handleAction("monitoring", "edit-monitoring", record)
            }
            icon={<EditOutlined />}
            className="ant-btn-reset"
          />
        ),
      },
      cPendingMountingActions: {
        title: "Actions",
        dataIndex: "",
        render: (record) => (
          <Button
            onClick={() =>
              this.handleAction(
                "pending-mounting",
                "edit-unmounting-photo",
                record
              )
            }
            icon={<EditOutlined />}
            className="ant-btn-reset"
          />
        ),
      },
      cCampaignMountingActions: {
        title: "Actions",
        dataIndex: "",
        render: (record) => (
          <Button
            onClick={() =>
              this.handleDeleteAdditionalCreative([record.operation_id])
            }
            style={{ background: "none" }}
            icon={<DeleteOutlined />}
            className="ant-btn-reset"
            disabled={record.operation_status === "Mounted" ? true : false}
          />
        ),
      },
      cCampaignMonitoringActions: {
        title: "Actions",
        dataIndex: "",
        render: (record) => (
          <Button
            onClick={() =>
              this.handleDeleteMonitoring([record.operation_monitoring_id])
            }
            icon={<DeleteOutlined />}
            className="ant-btn-reset"
            disabled={record.monitoring_done ? true : false}
            style={{ background: "none" }}
          />
        ),
      },
    };

    let colF = {
      cGeneral: {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      cIID: { columnName: "inventory_code", displayName: "IID" },
      cState: { columnName: "state", displayName: "State" },
      cDistrict: { columnName: "district", displayName: "District" },
      cCity: { columnName: "city", displayName: "City" },
      cArea: { columnName: "area", displayName: "Area" },
      cLocation: { columnName: "location", displayName: "Location" },
      cSize: { columnName: "size", displayName: "Size" },
      cProjectId: { columnName: "project_id", displayName: "Project Id" },
      cEmployee: { columnName: "employee_id", displayName: "Employee" },
      cCustomerName: {
        columnName: "customer_name",
        displayName: "Customer Name",
      },
      cDisplay: { columnName: "display_name", displayName: "Display" },
      cCreativeName: {
        columnName: "creative_name",
        displayName: "Creative Name",
      },
      cMonitoringName: {
        columnName: "monitoring_name",
        displayName: "Monitoring Name",
      },
      cSiteStartDate: {
        columnName: "start_date",
        displayName: "Site Start Date",
      },
      cSiteEndDate: { columnName: "end_date", displayName: "Site End Date" },
      cOperationStatus: {
        columnName: "operation_status",
        displayName: "Operation Status",
      },
      cSupplier: { columnName: "supplier_name", displayName: "Supplier" },
      cSearchTags: {
        columnName: "search_tags",
        displayName: "Search Tags",
        hideFilter: true,
      },
      cPlannedMountingDate: {
        columnName: "installation_date",
        displayName: "Planned Mounting Date",
      },
      cFlexReceivedDate: {
        columnName: "flex_received_date",
        displayName: "Flex Received Date",
      },
      cAssignedMounter: {
        columnName: "assigned_mounter_id",
        displayName: "Assigned Mounter",
      },
      cBookingStatus: {
        columnName: "booking_status",
        displayName: "Booking Status",
      },
      cMountingDelayedBy: {
        columnName: "mounting_delayed_by",
        displayName: "Mounting Delayed By",
      },
      cActualMountingDate: {
        columnName: "mounting_date",
        displayName: "Actual Mounting Date",
      },
      cLossDuetoDelayed: {
        columnName: "loss_due_to_delayed_mounting",
        displayName: "Loss Due to Delayed",
      },
      cMountedPhoto: {
        columnName: "mounting_photos",
        displayName: "Mounted Photo",
      },
      cPlannedMonitoringDate: {
        columnName: "monitoring_date",
        displayName: "Planned Monitoring Date",
      },
      cMonitoringType: {
        columnName: "monitoring_type",
        displayName: "Monitoring Type",
      },
      cMonitoringStatus: {
        columnName: "monitoring_done",
        displayName: "Monitoring Status",
      },
      cActualMonitoringDate: {
        columnName: "monitoring_performed_date",
        displayName: "Actual Monitoring Date",
      },
      cMonitoringDelayedBy: {
        columnName: "monitoring_delayed_by",
        displayName: "Monitoring Delayed By",
      },
      cPerformedBy: {
        columnName: "monitoring_by",
        displayName: "Performed By",
      },
      cMonitoringPhoto: {
        columnName: "monitoring_photo",
        displayName: "Monitoring Photo",
      },
      cNextDisplay: {
        columnName: "next_display_name",
        displayName: "Next Display",
      },
      cAssignedUnmounter: {
        columnName: "assigned_unmounter_id",
        displayName: "Assigned Unmounter",
      },
      cUnmountingPhotoDelayedBy: {
        columnName: "unmounting_delayed_by",
        displayName: "Unmounting Photo Delayed By",
      },
      cUnmountedFlexDelayedBy: {
        columnName: "unmounting_flex_delayed_by",
        displayName: "Unmounted Flex Delayed By",
      },
      cActualUnmountingPhotoDate: {
        columnName: "unmounting_photo_performed_date",
        displayName: "Actual Unmounting Photo Date",
      },
      cUnmountingPhotoBy: {
        columnName: "unmounting_photo_by",
        displayName: "Unmounting Photo By",
      },
      cUnmountedPhoto: {
        columnName: "unmounting_photos",
        displayName: "Unmounted Photo",
      },
      cBranch: {
        columnName: "branch_name",
        displayName: "Branch",
      },
      cSQFT: {
        columnName: "sqft",
        displayName: "SQFT",
      },
      cWeightinKG: {
        columnName: "flex_weight",
        displayName: "Weight in KG",
      },
      cUnmountedFlexReceivedDate: {
        columnName: "unmounted_flex_received_date",
        displayName: "Unmounted Flex Received Date",
      },
      cUnmountingDelayedBy: {
        columnName: "unmounting_delayed_by",
        displayName: "Unmounting Delayed By",
      },
      cAdvantageExploitedByClient: {
        columnName: "advantage_exploited_by_client",
        displayName: "Advantage Exploited By Client",
      },
      cUnmountingDoneBy: {
        columnName: "flex_unmounted_by",
        displayName: "Unmounting Done By",
      },
      cUnmountedPhotoStock: {
        columnName: "unmounted_photo",
        displayName: "Unmounted Photo",
      },
      cFlexReceivedDateCampaign: {
        columnName: "flex_received_date",
        displayName: "Flex Received Date",
      },
      cMedia: {
        columnName: "media_type",
        displayName: "Media",
      },
      cQty: {
        columnName: "quantity",
        displayName: "Qty",
      },
      cLight: {
        columnName: "light_type",
        displayName: "Light",
      },
      cUnmountingPhotoDate: {
        columnName: "unmounting_photo_date",
        displayName: "Unmounting Photo Date",
      },
      cUnmountingFlexReceivedOn: {
        columnName: "unmounted_flex_received_date",
        displayName: "Unmounting Flex Received On",
      },
      cPlannedUnmountingPhotoDate: {
        columnName: "unmounting_date",
        displayName: "Planned Unmounting Photo Date",
      },
      cActualUnmountingPhotoPerformedDate: {
        columnName: "unmounting_photo_performed_date",
        displayName: "Actual Unmounting Photo Performed Date",
      },
      cUnmountedFlexReceiveDate: {
        columnName: "unmounted_flex_received_date",
        displayName: "Unmounted Flex Receive Date",
      },
      cPrintingCost: {
        columnName: "printing_cost",
        displayName: "Printing Cost",
      },
      cInstallingCost: {
        columnName: "installing_cost",
        displayName: "Mounting Cost",
      },
    };

    switch (page) {
      case "waiting-for-flex":
        columns = [
          col?.cIID,
          col?.cSupplier,
          col?.cState,
          col?.cDistrict,
          col?.cCity,
          col?.cArea,
          col?.cLocation,
          col?.cSize,
          col?.cProjectId,
          col?.cEmployee,
          col?.cCustomerName,
          col?.cDisplay,
          col?.cCreativeName,
          col?.cPlannedMountingDate,
          col?.cOperationStatus,
          col?.cSearchTags,
        ];
        filterColumns = [
          colF?.cGeneral,
          colF?.cIID,
          colF?.cSupplier,
          colF?.cState,
          colF?.cDistrict,
          colF?.cCity,
          colF?.cArea,
          colF?.cLocation,
          colF?.cSize,
          colF?.cProjectId,
          colF?.cEmployee,
          colF?.cCustomerName,
          colF?.cDisplay,
          colF?.cCreativeName,
          colF?.cPlannedMountingDate,
          colF?.cOperationStatus,
          colF?.cSearchTags,
        ];

        if (isHistory) {
          columns = [
            col?.cIID,
            col?.cSupplier,
            col?.cState,
            col?.cDistrict,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cProjectId,
            col?.cEmployee,
            col?.cCustomerName,
            col?.cDisplay,
            col?.cCreativeName,
            col?.cFlexReceivedDate,
            col?.cOperationStatus,
            col?.cSearchTags,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cIID,
            colF?.cSupplier,
            colF?.cState,
            colF?.cDistrict,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cProjectId,
            colF?.cEmployee,
            colF?.cCustomerName,
            colF?.cDisplay,
            colF?.cCreativeName,
            colF?.cFlexReceivedDate,
            colF?.cOperationStatus,
            colF?.cSearchTags,
          ];
        }
        break;

      case "pending-mounting":
        columns = [
          col?.cIID,
          col?.cSupplier,
          col?.cState,
          col?.cDistrict,
          col?.cCity,
          col?.cArea,
          col?.cLocation,
          col?.cSize,
          col?.cProjectId,
          col?.cEmployee,
          col?.cCustomerName,
          col?.cDisplay,
          col?.cCreativeName,
          col?.cPlannedMountingDate,
          col?.cAssignedMounter,
          col?.cOperationStatus,
          col?.cBookingStatus,
          col?.cMountingDelayedBy,
          col?.cSearchTags,
        ];
        filterColumns = [
          colF?.cGeneral,
          colF?.cIID,
          colF?.cSupplier,
          colF?.cState,
          colF?.cDistrict,
          colF?.cCity,
          colF?.cArea,
          colF?.cLocation,
          colF?.cSize,
          colF?.cProjectId,
          colF?.cEmployee,
          colF?.cCustomerName,
          colF?.cDisplay,
          colF?.cCreativeName,
          colF?.cPlannedMountingDate,
          colF?.cAssignedMounter,
          colF?.cOperationStatus,
          colF?.cBookingStatus,
          colF?.cMountingDelayedBy,
          colF?.cSearchTags,
        ];

        if (isHistory) {
          columns = [
            col?.cIID,
            col?.cSupplier,
            col?.cState,
            col?.cDistrict,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cProjectId,
            col?.cEmployee,
            col?.cCustomerName,
            col?.cDisplay,
            col?.cCreativeName,
            col?.cPlannedMountingDate,
            col?.cActualMountingDate,
            col?.cMountingDelayedBy,
            col?.cLossDuetoDelayed,
            col?.cMountedPhoto,
            col?.cAssignedMounter,
            col?.cOperationStatus,
            col?.cMountingActions,
            col?.cSearchTags,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cIID,
            colF?.cSupplier,
            colF?.cState,
            colF?.cDistrict,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cProjectId,
            colF?.cEmployee,
            colF?.cCustomerName,
            colF?.cDisplay,
            colF?.cCreativeName,
            colF?.cPlannedMountingDate,
            colF?.cActualMountingDate,
            colF?.cMountingDelayedBy,
            colF?.cLossDuetoDelayed,
            colF?.cMountedPhoto,
            colF?.cAssignedMounter,
            colF?.cOperationStatus,
            colF?.cSearchTags,
          ];
        }
        break;

      case "pending-unmounting-photo":
        columns = [
          col?.cIID,
          col?.cSupplier,
          col?.cState,
          col?.cDistrict,
          col?.cCity,
          col?.cArea,
          col?.cLocation,
          col?.cSize,
          col?.cProjectId,
          col?.cEmployee,
          col?.cCustomerName,
          col?.cDisplay,
          col?.cCreativeName,
          col?.cNextDisplay,
          col?.cPlannedUnmountingPhotoDate,
          col?.cAssignedUnmounter,
          col?.cOperationStatus,
          col?.cBookingStatus,
          col?.cUnmountingPhotoDelayedBy,
          col?.cSearchTags,
        ];
        filterColumns = [
          colF?.cGeneral,
          colF?.cIID,
          colF?.cSupplier,
          colF?.cState,
          colF?.cDistrict,
          colF?.cCity,
          colF?.cArea,
          colF?.cLocation,
          colF?.cSize,
          colF?.cProjectId,
          colF?.cEmployee,
          colF?.cCustomerName,
          colF?.cDisplay,
          colF?.cCreativeName,
          colF?.cNextDisplay,
          colF?.cPlannedUnmountingPhotoDate,
          colF?.cAssignedUnmounter,
          colF?.cOperationStatus,
          colF?.cBookingStatus,
          colF?.cUnmountingPhotoDelayedBy,
          colF?.cSearchTags,
        ];

        if (isHistory) {
          columns = [
            col?.cIID,
            col?.cSupplier,
            col?.cState,
            col?.cDistrict,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cProjectId,
            col?.cEmployee,
            col?.cCustomerName,
            col?.cDisplay,
            col?.cCreativeName,
            col?.cPlannedUnmountingPhotoDate,
            col?.cActualUnmountingPhotoPerformedDate,
            col?.cUnmountingPhotoBy,
            col?.cUnmountedPhoto,
            col?.cAssignedUnmounter,
            col?.cOperationStatus,
            col?.cBookingStatus,
            col?.cUnmountingPhotoDelayedBy,
            col?.cSearchTags,
            col?.cPendingMountingActions,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cIID,
            colF?.cSupplier,
            colF?.cState,
            colF?.cDistrict,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cProjectId,
            colF?.cEmployee,
            colF?.cCustomerName,
            colF?.cDisplay,
            colF?.cCreativeName,
            colF?.cPlannedUnmountingPhotoDate,
            colF?.cActualUnmountingPhotoPerformedDate,
            colF?.cUnmountingPhotoBy,
            colF?.cUnmountedPhoto,
            colF?.cAssignedUnmounter,
            colF?.cOperationStatus,
            colF?.cBookingStatus,
            colF?.cUnmountingPhotoDelayedBy,
            colF?.cSearchTags,
          ];
        }
        break;

      case "pending-unmounting":
        columns = [
          col?.cIID,
          col?.cSupplier,
          col?.cState,
          col?.cDistrict,
          col?.cCity,
          col?.cArea,
          col?.cLocation,
          col?.cSize,
          col?.cProjectId,
          col?.cEmployee,
          col?.cCustomerName,
          col?.cDisplay,
          col?.cCreativeName,
          col?.cSiteStartDate,
          col?.cSiteEndDate,
          col?.cActualUnmountingPhotoDate,
          col?.cUnmountedPhoto,
          col?.cOperationStatus,
          col?.cUnmountedFlexDelayedBy,
          col?.cSearchTags,
        ];
        filterColumns = [
          colF?.cGeneral,
          colF?.cIID,
          colF?.cSupplier,
          colF?.cState,
          colF?.cDistrict,
          colF?.cCity,
          colF?.cArea,
          colF?.cLocation,
          colF?.cSize,
          colF?.cProjectId,
          colF?.cEmployee,
          colF?.cCustomerName,
          colF?.cDisplay,
          colF?.cCreativeName,
          colF?.cSiteStartDate,
          colF?.cSiteEndDate,
          colF?.cActualUnmountingPhotoDate,
          colF?.cUnmountedPhoto,
          colF?.cOperationStatus,
          colF?.cUnmountedFlexDelayedBy,
          colF?.cSearchTags,
        ];

        if (isHistory) {
          columns = [
            col?.cIID,
            col?.cSupplier,
            col?.cState,
            col?.cDistrict,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cProjectId,
            col?.cEmployee,
            col?.cCustomerName,
            col?.cDisplay,
            col?.cCreativeName,
            col?.cSiteStartDate,
            col?.cSiteEndDate,
            col?.cActualUnmountingPhotoDate,
            col?.cUnmountedFlexReceiveDate,
            col?.cUnmountedPhoto,
            col?.cOperationStatus,
            col?.cUnmountedFlexDelayedBy,
            col?.cSearchTags,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cIID,
            colF?.cSupplier,
            colF?.cState,
            colF?.cDistrict,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cProjectId,
            colF?.cEmployee,
            colF?.cCustomerName,
            colF?.cDisplay,
            colF?.cCreativeName,
            colF?.cSiteStartDate,
            colF?.cSiteEndDate,
            colF?.cActualUnmountingPhotoDate,
            colF?.cUnmountedFlexReceiveDate,
            colF?.cUnmountedPhoto,
            colF?.cOperationStatus,
            colF?.cUnmountedFlexDelayedBy,
            colF?.cSearchTags,
          ];
        }
        break;

      case "monitoring":
        columns = [
          col?.cIID,
          col?.cSupplier,
          col?.cState,
          col?.cDistrict,
          col?.cCity,
          col?.cArea,
          col?.cLocation,
          col?.cSize,
          col?.cProjectId,
          col?.cEmployee,
          col?.cCustomerName,
          col?.cDisplay,
          col?.cCreativeName,
          col?.cMonitoringName,
          col?.cPlannedMonitoringDate,
          col?.cMonitoringType,
          col?.cMonitoringStatus,
          col?.cOperationStatus,
          col?.cSearchTags,
        ];
        filterColumns = [
          colF?.cGeneral,
          colF?.cIID,
          colF?.cSupplier,
          colF?.cState,
          colF?.cDistrict,
          colF?.cCity,
          colF?.cArea,
          colF?.cLocation,
          colF?.cSize,
          colF?.cProjectId,
          colF?.cEmployee,
          colF?.cCustomerName,
          colF?.cDisplay,
          colF?.cCreativeName,
          colF?.cMonitoringName,
          colF?.cPlannedMonitoringDate,
          colF?.cMonitoringType,
          colF?.cMonitoringStatus,
          colF?.cOperationStatus,
          colF?.cSearchTags,
        ];

        if (isHistory) {
          columns = [
            col?.cIID,
            col?.cSupplier,
            col?.cState,
            col?.cDistrict,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cProjectId,
            col?.cEmployee,
            col?.cCustomerName,
            col?.cDisplay,
            col?.cCreativeName,
            col?.cMonitoringName,
            col?.cPlannedMonitoringDate,
            col?.cActualMonitoringDate,
            col?.cMonitoringType,
            col?.cPerformedBy,
            col?.cMonitoringPhoto,
            col?.cMonitoringStatus,
            col?.cOperationStatus,
            col?.cSearchTags,
            col?.cMonitoringAction,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cIID,
            colF?.cSupplier,
            colF?.cState,
            colF?.cDistrict,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cProjectId,
            colF?.cEmployee,
            colF?.cCustomerName,
            colF?.cDisplay,
            colF?.cCreativeName,
            colF?.cMonitoringName,
            colF?.cPlannedMonitoringDate,
            colF?.cActualMonitoringDate,
            colF?.cMonitoringType,
            colF?.cPerformedBy,
            colF?.cMonitoringPhoto,
            colF?.cMonitoringStatus,
            colF?.cOperationStatus,
            colF?.cSearchTags,
          ];
        }
        break;

      case "mounting-unmounting":
        columns = [
          {
            title: "Location",
            dataIndex: "location",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 372,
            render: (text, record) =>
              text +
              (record?.quantity && record?.quantity > 1
                ? " (Qty : " +
                  record?.quantity +
                  " / Display : " +
                  (record?.side_type === "double"
                    ? record?.quantity * 2
                    : record?.quantity) +
                  ")"
                : ""),
          },
          {
            title: "Unmount Display",
            dataIndex: "unmount_display_name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 150,
          },
          {
            title: "Mount Display",
            dataIndex: "mount_display_name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 140,
          },
          {
            title: "Assigned Mounter",
            dataIndex: "assigned_labour_id",
            render: (text) => text && <PortalUser user={text} />,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 160,
          },
          {
            title: "Job Date",
            dataIndex: "job_date",
            render: (text) =>
              text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 92,
          },
          {
            title: "Search Tags",
            dataIndex: "search_tags",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 120,
          },
          {
            title: "Operations Status",
            dataIndex: "job_operation_status",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 160,
          },
          {
            title: "Actions",
            dataIndex: "",
            render: (record) => (
              <Button
                onClick={() =>
                  this.handleAction(
                    "mounting-unmounting",
                    "add-mounting-unmounting",
                    record
                  )
                }
                icon={<UploadOutlined />}
                className="ant-btn-reset"
              />
            ),
            width: 72,
          },
        ];

        filterColumns = [
          {
            columnName: "location",
            displayName: "Location",
            default: true,
          },
          {
            columnName: "unmount_display_name",
            displayName: "Unmount Display",
          },
          {
            columnName: "mount_display_name",
            displayName: "Mount Display",
          },
          {
            columnName: "assigned_labour_id",
            displayName: "Assigned Mounter",
            hideFilter: true,
          },
          { columnName: "job_date", displayName: "Job Date", hideFilter: true },
          {
            columnName: "job_operation_status",
            displayName: "Operations Status",
            hideFilter: true,
          },
          {
            columnName: "search_tags",
            displayName: "Search Tags",
            hideFilter: true,
          },
        ];

        if (isHistory) {
          columns.splice(6, 2, {
            title: "Actions",
            dataIndex: "",
            render: (record) => (
              <Button
                onClick={() =>
                  this.handleAction(
                    "mounting-unmounting",
                    "edit-mounting-unmounting",
                    record
                  )
                }
                icon={<EditOutlined />}
                className="ant-btn-reset"
              />
            ),
            width: 72,
          });
        }
        break;

      case "stock-management":
        columns = [
          col?.cBranch,
          col?.cIID,
          col?.cSupplier,
          col?.cState,
          col?.cDistrict,
          col?.cCity,
          col?.cArea,
          col?.cLocation,
          col?.cSize,
          col?.cSQFT,
          col?.cWeightinKG,
          col?.cEmployee,
          col?.cCustomerName,
          col?.cDisplay,
          col?.cCreativeName,
          col?.cSiteStartDate,
          col?.cSiteEndDate,
          col?.cActualUnmountingPhotoDate,
          col?.cUnmountedFlexReceivedDate,
          col?.cUnmountingDelayedBy,
          col?.cAdvantageExploitedByClient,
          col?.cUnmountingDoneBy,
          col?.cUnmountedPhotoStock,
          col?.cOperationStatus,
        ];
        filterColumns = [
          colF?.cGeneral,
          colF?.cBranch,
          colF?.cIID,
          colF?.cSupplier,
          colF?.cState,
          colF?.cDistrict,
          colF?.cCity,
          colF?.cArea,
          colF?.cLocation,
          colF?.cSize,
          colF?.cSQFT,
          colF?.cWeightinKG,
          colF?.cEmployee,
          colF?.cCustomerName,
          colF?.cDisplay,
          colF?.cCreativeName,
          colF?.cSiteStartDate,
          colF?.cSiteEndDate,
          colF?.cActualUnmountingPhotoDate,
          colF?.cUnmountedFlexReceivedDate,
          colF?.cUnmountingDelayedBy,
          colF?.cAdvantageExploitedByClient,
          colF?.cUnmountingDoneBy,
          colF?.cUnmountedPhotoStock,
          colF?.cOperationStatus,
        ];

        break;

      case "campaign-operations/:id":
        if (!this.state.activeTab || this.state.activeTab == "waitingforflex") {
          screen = "campaign-operations-waitingforflex";
          columns = [
            col?.cIID,
            col?.cSupplier,
            col?.cState,
            col?.cDistrict,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cCreativeName,
            col?.cSiteStartDate,
            col?.cSiteEndDate,
            col?.cFlexReceivedDateCampaign,
            col?.cOperationStatus,
            col?.cSearchTags,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cIID,
            colF?.cSupplier,
            colF?.cState,
            colF?.cDistrict,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cCreativeName,
            colF?.cSiteStartDate,
            colF?.cSiteEndDate,
            colF?.cFlexReceivedDateCampaign,
            colF?.cOperationStatus,
            colF?.cSearchTags,
          ];
        }

        if (this.state.activeTab == "mounting") {
          screen = "campaign-operations-mounting";
          columns = [
            col?.cMedia,
            col?.cSupplier,
            col?.cQty,
            col?.cIID,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cLight,
            col?.cCreativeName,
            col?.cPlannedMountingDate,
            col?.cActualMountingDate,
            col?.cMountedPhoto,
            col?.cPrintingCost,
            col?.cInstallingCost,
            col?.cOperationStatus,
            col?.cSearchTags,
            col?.cCampaignMountingActions,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cMedia,
            colF?.cSupplier,
            colF?.cQty,
            colF?.cIID,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cLight,
            colF?.cCreativeName,
            colF?.cPlannedMountingDate,
            colF?.cActualMountingDate,
            colF?.cMountedPhoto,
            colF?.cPrintingCost,
            colF?.cInstallingCost,
            colF?.cOperationStatus,
            colF?.cSearchTags,
          ];
        }

        if (this.state.activeTab == "monitoring") {
          screen = "campaign-operations-monitoring";
          columns = [
            col?.cMedia,
            col?.cSupplier,
            col?.cQty,
            col?.cIID,
            col?.cDistrict,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cLight,
            col?.cMonitoringType,
            col?.cMonitoringName,
            col?.cPlannedMonitoringDate,
            col?.cActualMonitoringDate,
            col?.cMonitoringPhoto,
            col?.cMonitoringStatus,
            col?.cSearchTags,
            col?.cCampaignMonitoringActions,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cMedia,
            colF?.cSupplier,
            colF?.cQty,
            colF?.cIID,
            colF?.cDistrict,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cLight,
            colF?.cMonitoringType,
            colF?.cMonitoringName,
            colF?.cPlannedMonitoringDate,
            colF?.cActualMonitoringDate,
            colF?.cMonitoringPhoto,
            colF?.cMonitoringStatus,
            colF?.cSearchTags,
          ];
        }

        if (this.state.activeTab == "unmounting") {
          screen = "campaign-operations-unmounting";
          columns = [
            col?.cMedia,
            col?.cSupplier,
            col?.cQty,
            col?.cIID,
            col?.cDistrict,
            col?.cCity,
            col?.cArea,
            col?.cLocation,
            col?.cSize,
            col?.cLight,
            col?.cBookingStatus,
            col?.cSiteStartDate,
            col?.cSiteEndDate,
            col?.cPlannedUnmountingPhotoDate,
            col?.cActualUnmountingPhotoPerformedDate,
            col?.cUnmountingFlexReceivedOn,
            col?.cUnmountingDelayedBy,
            col?.cAdvantageExploitedByClient,
            col?.cUnmountingDoneBy,
            col?.cUnmountedPhoto,
            col?.cOperationStatus,
            col?.cSearchTags,
          ];
          filterColumns = [
            colF?.cGeneral,
            colF?.cMedia,
            colF?.cSupplier,
            colF?.cQty,
            colF?.cIID,
            colF?.cDistrict,
            colF?.cCity,
            colF?.cArea,
            colF?.cLocation,
            colF?.cSize,
            colF?.cLight,
            colF?.cBookingStatus,
            colF?.cSiteStartDate,
            colF?.cSiteEndDate,
            colF?.cPlannedUnmountingPhotoDate,
            colF?.cActualUnmountingPhotoPerformedDate,
            colF?.cUnmountingFlexReceivedOn,
            colF?.cUnmountingDelayedBy,
            colF?.cAdvantageExploitedByClient,
            colF?.cUnmountingDoneBy,
            colF?.cUnmountedPhoto,
            colF?.cOperationStatus,
            colF?.cSearchTags,
          ];
        }
        break;

      case "change-of-site":
        columns = [
          {
            title: "COS Date",
            dataIndex: "cos_date",
            render: (text) =>
              text !== "0001-01-01T00:00:00" ? dateFormatter(text) : "",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
          },
          {
            title: "Employee Name",
            dataIndex: "employee_id",
            render: (text) => <PortalUser user={text} />,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
          },
          {
            title: "Change Flex From ",
            dataIndex: "change_flex_from",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
          },
          {
            title: "Photo",
            dataIndex: "from_inventory_photos",
            render: (text, record) => (
              <Button
                className="ant-btn-reset"
                disabled={
                  record?.from_inventory_photos?.length > 0 ? false : true
                }
                icon={<FileImageOutlined />}
                onClick={() =>
                  this.handlePhotos(record, "from_inventory_photos")
                }
              />
            ),
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
          },
          {
            title: "Change Flex To",
            dataIndex: "change_flex_to",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
          },
          {
            title: "Photo",
            dataIndex: "to_inventory_photos",
            render: (text, record) => (
              <Button
                className="ant-btn-reset"
                disabled={
                  record?.from_inventory_photos?.length > 0 ? false : true
                }
                icon={<FileImageOutlined />}
                onClick={() => this.handlePhotos(record, "to_inventory_photos")}
              />
            ),
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
          },
          {
            title: "Current Display",
            dataIndex: "display_name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
          },
        ];

        filterColumns = [
          {
            columnName: "general",
            displayName: "General",
            default: true,
            hideFromSettings: true,
          },
          { columnName: "cos_date", displayName: "COS Date" },
          { columnName: "employee_id", displayName: "Employee Name" },
          { columnName: "change_flex_from", displayName: "Change Flex From" },
          { columnName: "change_flex_to", displayName: "Change Flex To" },
          { columnName: "display_name", displayName: "Current Display" },
        ];
        break;

      default:
        break;
    }

    this.setState({ columnsDefault: columns, filterColumns, screen }, () =>
      this.setDefaultColumns(columns)
    );
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  handleAction = (page, action, record) => {
    switch (action) {
      case "mark-as-read":
        this.handleMarkasRead();
        break;

      case "assign-mounter":
        this.setState(
          { modalTitle: "Assign Mounter", modalAction: action },
          () => this.handleModal(page, action)
        );
        break;

      case "add-mounting":
        this.setState({ modalTitle: "Add Mounting", modalAction: action }, () =>
          this.handleModal(page, action)
        );
        break;

      case "edit-mounting":
        this.setState(
          {
            modalTitle: "Edit Mounting",
            modalAction: action,
            editRecord: record,
          },
          () => this.handleModal(page, action)
        );
        break;

      case "assign-unmounter":
        this.setState(
          { modalTitle: "Assign Unmounter", modalAction: action },
          () => this.handleModal(page, action)
        );
        break;

      case "add-unmounting-photo":
        this.setState(
          { modalTitle: "Add Unmounting Photo", modalAction: action },
          () => this.handleModal(page, action)
        );
        break;

      case "edit-unmounting-photo":
        this.setState(
          {
            modalTitle: "Edit Unmounting Photo",
            modalAction: action,
            editRecord: record,
          },
          () => this.handleModal(page, action)
        );
        break;

      case "receive-unmounted-flex":
        this.setState(
          { modalTitle: "Receive Unmounted Flex", modalAction: action },
          () => this.handleModal(page, action)
        );
        break;

      case "add-monitoring":
        this.setState(
          { modalTitle: "Add Monitoring", modalAction: action },
          () => this.handleModal(page, action)
        );
        break;

      case "edit-monitoring":
        this.setState(
          {
            modalTitle: "Edit Monitoring",
            modalAction: action,
            editRecord: record,
          },
          () => this.handleModal(page, action)
        );
        break;

      case "mark-as-completed":
        this.handleMarkasCompleted();
        break;

      case "setup-monitoring":
        this.handleSetupMonitoring();
        break;

      case "returned-to-clients":
        this.handleReturnedtoClients();
        break;

      case "sold":
        this.setState({ modalTitle: "Sold", modalAction: action }, () =>
          this.handleModal(page, action)
        );
        break;

      case "add-additional-creative":
        this.setState(
          { modalTitle: "Add Additional Creative", modalAction: action },
          () => this.handleModal(page, action)
        );
        break;

      case "additional-printing-mounting":
        this.setState(
          { modalTitle: "Additional Printing Mounting", modalAction: action },
          () => this.handleModal(page, action)
        );
        break;

      case "add-campaign-monitoring":
        this.setState(
          { modalTitle: "Add Monitoring", modalAction: action },
          () => this.handleModal(page, action)
        );
        break;

      case "delete-additional-creative":
        this.handleDeleteAdditionalCreative();
        break;

      case "delete-campaign-monitoring":
        this.handleDeleteMonitoring();
        break;

      case "add-mounting-unmounting":
        this.setState(
          {
            modalTitle: "Add Mounting Unmounting",
            modalAction: action,
            editRecord: record,
          },
          () => this.handleModal(page, action)
        );
        break;

      case "edit-mounting-unmounting":
        this.setState(
          {
            modalTitle: "Edit Mounting Unmounting",
            modalAction: action,
            editRecord: record,
          },
          () => this.handleModal(page, action)
        );
        break;

      case "excel-export":
        this.setState({ modalTitle: "Excel Export", modalAction: action }, () =>
          this.handleModal(page, action)
        );
        break;

      default:
        break;
    }
  };

  handleMarkasRead = () => {
    const { selectedRowKeys } = this.state;
    const { pagination } = this.state;

    let reqUrl = "operation/flexreceived";
    let reqObj = {
      request: {
        operation_id: selectedRowKeys,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], selectedRows: [] });
      if (data?.success) {
        message.success("Records Mark as Read Successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  handleModal = (page, action) => {
    this.setState({
      isModal: !this.state.isModal,
      refreshId: this.state.refreshId + 1,
    });
  };

  handleSwitch = (action) => {
    const { pagination } = this.state;

    if (action) {
      this.setState(
        {
          listReq: this.state.listPathToggle,
          screen: this.state.page + "-history",
        },
        () => (this.getResults({ pagination }), this.setColumns())
      );
    } else {
      this.setState(
        { listReq: this.state.listPath, screen: this.state.page },
        () => (this.getResults({ pagination }), this.setColumns())
      );
    }
  };

  handleAssignMounter = (assigned_mounter_id) => {
    this.setState({ isModal: !this.state.isModal });

    const { selectedRowKeys, modalAction } = this.state;
    const { pagination } = this.state;

    let reqUrl =
      modalAction === "assign-mounter"
        ? "operation/mounter/assign"
        : "operation/unmounter/assign";
    let reqObj = {
      request: {
        operation_id: selectedRowKeys,
        assigned_mounter_id: assigned_mounter_id,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], selectedRows: [] });
      if (data?.success) {
        message.success("Mounter Assigned Successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  handleAssignMounterUnmounter = (assigned_mounter_id) => {
    this.setState({ isModal: !this.state.isModal });

    const { selectedRowKeys, modalAction } = this.state;
    const { pagination } = this.state;

    let reqUrl = "";
    let reqObj = {};

    let unmountOperationId = selectedRowKeys
      .map((x) => Number(x.split("-")[0]))
      .filter((x) => x !== 0);

    let mountOperationId = selectedRowKeys
      .map((x) => Number(x.split("-")[1]))
      .filter((x) => x !== 0);

    if (unmountOperationId?.length > 0) {
      reqUrl = "operation/unmounter/assign";
      reqObj = {
        request: {
          operation_id: unmountOperationId,
          assigned_mounter_id: assigned_mounter_id,
        },
      };
      ajaxRequester(reqUrl, reqObj, (data) => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
        if (data?.success) {
          message.success("Mounter Assigned Successfully.");
          this.getResults({ pagination });
        } else {
          message.error("Something went wrong.");
        }
      });
    }

    if (mountOperationId?.length > 0) {
      reqUrl = "operation/mounter/assign";
      reqObj = {
        request: {
          operation_id: mountOperationId,
          assigned_mounter_id: assigned_mounter_id,
        },
      };
      ajaxRequester(reqUrl, reqObj, (data) => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
        if (data?.success) {
          message.success("Mounter Assigned Successfully.");
          this.getResults({ pagination });
        } else {
          message.error("Something went wrong.");
        }
      });
    }
  };

  handleAddMounting = (data) => {
    this.setState({ isModal: !this.state.isModal });

    const { selectedRows, selectedRowKeys, modalAction, editRecord } =
      this.state;
    const { pagination } = this.state;

    let validRows = [];
    let InvalidRows = [];

    if (modalAction === "add-mounting") {
      selectedRows.map((x) =>
        moment(data.mounting_date).isBetween(
          moment(x.start_date).subtract(1, "days"),
          moment(x.end_date)
        )
          ? validRows.push(x.operation_id)
          : InvalidRows.push(x.inventory_code)
      );
    }

    if (modalAction === "edit-mounting") {
      moment(data.mounting_date).isBetween(
        moment(editRecord.start_date).subtract(1, "days"),
        moment(editRecord.end_date)
      )
        ? validRows.push(editRecord.operation_id)
        : InvalidRows.push(editRecord.inventory_code);
    }

    // Temparary Fixed removed unmounting photo validation
    if (modalAction === "add-unmounting-photo") {
      selectedRows.map((x) =>
        moment(data.mounting_date).isSameOrAfter(moment(x.end_date))
          ? validRows.push(x.operation_id)
          : validRows.push(x.operation_id)
      );
    }

    if (modalAction === "edit-unmounting-photo") {
      moment(data.mounting_date).isSameOrAfter(moment(editRecord.end_date))
        ? validRows.push(editRecord.operation_id)
        : InvalidRows.push(editRecord.inventory_code);
    }

    let reqUrl = "";
    let reqObj = "";
    let d_date = resDate(data?.mounting_date);

    switch (modalAction) {
      case "add-mounting":
        reqUrl = "operation/mounting/add";
        reqObj = {
          request: {
            operation_id: validRows,
            mounting_date: d_date,
          },
        };
        break;

      case "add-monitoring":
        reqUrl = "operation/monitoring/add";
        reqObj = {
          request: {
            operation_monitoring_id: selectedRowKeys,
            monitoring_performed_date: d_date,
          },
        };
        break;

      case "add-unmounting-photo":
        reqUrl = "operation/unmounting/add";
        reqObj = {
          request: {
            operation_id: validRows,
            unmounting_date: d_date,
          },
        };
        break;

      case "edit-mounting":
        reqUrl = "operation/mounting/update";
        reqObj = {
          request: {
            operation_id: validRows,
            mounting_date: d_date,
          },
        };
        break;

      case "edit-monitoring":
        reqUrl = "operation/monitoring/update";
        reqObj = {
          request: {
            operation_monitoring_id: [editRecord?.operation_monitoring_id],
            monitoring_performed_date: d_date,
          },
        };
        break;

      case "edit-unmounting-photo":
        reqUrl = "operation/unmounting/update";
        reqObj = {
          request: {
            operation_id: validRows,
            unmounting_date: d_date,
          },
        };
        break;

      case "add-mounting-unmounting":
        reqUrl = "operation/job/add";

        if (
          editRecord?.unmount_operation_id &&
          editRecord?.mount_operation_id
        ) {
          reqObj = {
            request: {
              unmount_operation_id: editRecord?.unmount_operation_id,
              mount_operation_id: editRecord?.mount_operation_id,
              job_date: d_date,
            },
          };
        }

        if (
          editRecord?.unmount_operation_id &&
          !editRecord?.mount_operation_id
        ) {
          reqObj = {
            request: {
              unmount_operation_id: editRecord?.unmount_operation_id,
              job_date: d_date,
            },
          };
        }

        if (
          editRecord?.mount_operation_id &&
          !editRecord?.unmount_operation_id
        ) {
          reqObj = {
            request: {
              mount_operation_id: editRecord?.mount_operation_id,
              job_date: d_date,
            },
          };
        }
        break;

      case "edit-mounting-unmounting":
        reqUrl = "operation/job/update";

        if (
          editRecord?.unmount_operation_id &&
          editRecord?.mount_operation_id
        ) {
          reqObj = {
            request: {
              unmount_operation_id: editRecord?.unmount_operation_id,
              mount_operation_id: editRecord?.mount_operation_id,
              job_date: d_date,
            },
          };
        }

        if (editRecord?.unmount_operation_id) {
          reqObj = {
            request: {
              unmount_operation_id: editRecord?.unmount_operation_id,
              job_date: d_date,
            },
          };
        }

        if (editRecord?.mount_operation_id) {
          reqObj = {
            request: {
              mount_operation_id: editRecord?.mount_operation_id,
              job_date: d_date,
            },
          };
        }
        break;

      default:
        break;
    }

    if (
      modalAction === "add-mounting" ||
      modalAction === "add-unmounting-photo" ||
      modalAction === "edit-mounting" ||
      modalAction === "edit-unmounting-photo"
    ) {
      if (validRows.length > 0) {
        ajaxRequester(reqUrl, reqObj, (data) => {
          this.setState({ selectedRowKeys: [], selectedRows: [] });
          if (data?.success) {
            message.success("Successfully Done.");
            this.getResults({ pagination });
          } else {
            if (modalAction === "add-mounting") {
              message.error(
                data?.detail ||
                  "Can not add Mounting, as mounter is not assigned for the selected inventory"
              );
            } else {
              message.error("Something went wrong.");
            }
          }
        });
        if (InvalidRows.length > 0) {
          if (
            modalAction === "add-mounting" ||
            modalAction === "edit-mounting"
          ) {
            message.error("Mounting Date cannot be Greater than Start Date");
          } else {
            message.error("Date cannot be Greater than Start Date");
          }
        }
      } else {
        if (modalAction === "add-mounting" || modalAction === "edit-mounting") {
          message.error("Mounting Date cannot be Greater than Start Date");
        } else {
          message.error("Date cannot be Greater than Start Date");
        }
      }
    } else {
      ajaxRequester(reqUrl, reqObj, (data) => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
        if (data?.success) {
          message.success("Successfully Done.");
          this.getResults({ pagination });
        } else {
          if (modalAction !== "add-mounting-unmounting") {
            message.error("Something went wrong.");
          } else {
            message.error(data?.detail || "Please Assign Mounter First.");
          }
        }
      });
    }
  };

  handleReceiveUnmountedFlex = (data) => {
    this.setState({ isModal: !this.state.isModal });

    const { selectedRowKeys } = this.state;
    const { pagination } = this.state;

    let reqUrl = "operation/unmountedflexreceived";
    let reqObj = {
      request: {
        operation_id: selectedRowKeys,
        flex_received_date: data?.flex_received_date,
        flex_received_status: data?.flex_received_status || "O",
        remarks: data?.remarks,
        branch_id: data?.branch_id,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], selectedRows: [] });
      if (data?.success) {
        message.success("Receive Unmounted Flex Successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  handleMarkasCompleted = () => {
    const { selectedRowKeys } = this.state;
    const { pagination } = this.state;

    let reqUrl = "operation/markascompleted";
    let reqObj = {
      request: {
        operation_id: selectedRowKeys[0],
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], selectedRows: [] });
      if (data?.success) {
        message.success("Records Mark as Completed Successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  handleSetupMonitoring = () => {
    const { selectedRowKeys } = this.state;
    const { pagination } = this.state;

    let reqUrl = "operation/monitoring/setup/add";
    let reqObj = {
      request: {
        operation_id: selectedRowKeys,
        monitoring_name: "Amit Test Monitoring",
        monitoring_date: "2021-05-15",
        monitoring_type: "Night",
        monitoring_by: "amit",
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], selectedRows: [] });
      if (data?.success) {
        message.success("Monitoring added successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  handleReturnedtoClients = () => {
    const { selectedRowKeys } = this.state;
    const { pagination } = this.state;

    let reqUrl = "operation/stockonhand/returnitems";
    let reqObj = {
      request: {
        operation_id: selectedRowKeys,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], selectedRows: [] });
      if (data?.success) {
        message.success("Returned to Clients Successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  handleCreateGroup = (data) => {
    const { selectedRowKeys } = this.state;

    let reqUrl = "operation/stockonhand/labeling/add";
    let reqObj = {
      request: {
        operation_id: selectedRowKeys,
        label_name: data?.label_name,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success) {
        this.getGroupDetails(reqObj.request.label_name);
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  getGroupDetails = (data) => {
    let reqUrl = "operation/stockonhand/labeling/detail?labelname=" + data;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ groupDetails: data.response });
      },
      "GET"
    );
  };

  handleSold = (data) => {
    this.setState({ isModal: !this.state.isModal });
    const { pagination } = this.state;

    let reqUrl = "operation/stockonhand/sellitems";
    let reqObj = {
      request: data,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({
        selectedRowKeys: [],
        selectedRows: [],
        groupDetails: false,
      });
      if (data?.success) {
        message.success("Sold Successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong. Label Name already exists.");
      }
    });
  };

  handleTabEvent = (data) => {
    const { pagination } = this.state;

    let listPath = "";

    switch (data) {
      case "stockonhand":
        listPath = "operation/stockonhand/list?";
        break;

      case "stolentornflex":
        listPath = "operation/stolentornflex/list?";
        break;

      case "solditems":
        listPath = "operation/solditems/list?";
        break;

      case "returneditems":
        listPath = "operation/returneditems/list?";
        break;

      case "waitingforflex":
        listPath =
          "campaign/waitingforflex/list?campaign_id=" +
          this.props.match.params.id +
          "&";
        break;

      case "mounting":
        listPath =
          "campaign/mounting/list?campaign_id=" +
          this.props.match.params.id +
          "&";
        break;

      case "monitoring":
        listPath =
          "campaign/monitoring/list?campaign_id=" +
          this.props.match.params.id +
          "&";
        break;

      case "unmounting":
        listPath =
          "campaign/unmounting/list?campaign_id=" +
          this.props.match.params.id +
          "&";
        break;

      default:
        break;
    }

    this.setState(
      { listReq: listPath, listPath, activeTab: data, data: [] },
      () => (this.getResults({ pagination }), this.setColumns())
    );
  };

  handleAddCreative = (data) => {
    this.setState({ isModal: !this.state.isModal });
    const { pagination } = this.state;

    let reqUrl = "operation/additionalcreative/add";
    let reqObj = {
      request: data,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({
        selectedRowKeys: [],
        selectedRows: [],
      });

      if (data?.success) {
        message.success("Additional Creative added Successfully");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong. " + data);
      }
    });
  };

  handleAddMonitoring = (data) => {
    this.setState({ isModal: !this.state.isModal });
    const { pagination } = this.state;

    let reqUrl = "operation/monitoring/setup/add";
    let reqObj = {
      request: data,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({
        selectedRowKeys: [],
        selectedRows: [],
      });

      if (data?.success) {
        message.success("Monitoring added Successfully");
        this.getResults({ pagination });
      } else {
        message.error(data?.detail || "Something went wrong.");
      }
    });
  };

  handleDeleteAdditionalCreative = (id) => {
    const { selectedRowKeys } = this.state;
    const { pagination } = this.state;

    let reqUrl = "operation/additionalcreative/delete";
    let reqObj = {
      request: {
        operation_id: id,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], selectedRows: [] });
      if (data?.success) {
        message.success("Additional Creative Deleted Successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Cannot Delete Mounted Entry.");
      }
    });
  };

  handleDeleteMonitoring = (id) => {
    const { selectedRows } = this.state;
    const { pagination } = this.state;

    let operation_monitoring_id = [];

    selectedRows.map((x) =>
      operation_monitoring_id.push(x.operation_monitoring_id)
    );

    let reqUrl = "operation/monitoring/setup/delete";
    let reqObj = {
      request: {
        operation_monitoring_id: id,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], selectedRows: [] });
      if (data?.success) {
        message.success("Monitoring Deleted Successfully.");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  handleAdditionalPrintingMounting = (data) => {
    this.setState({ isModal: !this.state.isModal });
    const { pagination } = this.state;

    let additionalPrintingMounting =
      (data &&
        data.map((x) => ({
          operation_id: x?.operation_id,
          printing_cost: x?.printing_cost || 0,
          installing_cost: x?.installing_cost || 0,
        }))) ||
      [];

    let reqUrl = "operation/additionalrate/update";
    let reqObj = {
      request: additionalPrintingMounting,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({
        selectedRowKeys: [],
        selectedRows: [],
      });

      if (data?.success) {
        message.success("Additional Printing Mounting added Successfully");
        this.getResults({ pagination });
      } else {
        message.error("Something went wrong. " + data);
      }
    });
  };

  updateResults = () => {
    if (this.state?.listPath.includes("campaign/waitingforflex")) {
      this.setState({ operationsAllInventories: this.state.data });
    }
  };

  handlePhotos = (record, type) => {
    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record ? record[type] : "",
      photosTitle: record ? record : "",
    });
  };

  handleBack = () => {
    // this.props.history.push(`/inventories/inventories`);
    this.props.history.goBack();
  };

  // Redirect to inventory status screen
  statusInventory = (inventory, type) => {
    let inventoryTitle =
      inventory.inventory_code +
      " - " +
      inventory.city +
      ", " +
      inventory.location +
      " - " +
      inventory.width +
      "x" +
      inventory.height;

    if (type === "booking") {
      window.open(
        `/#/inventories/${
          type === "booking" ? "inventory-booking-status" : "inventory-status"
        }/${inventory.ha_inventory_id}/${inventoryTitle}`,
        "_blank"
      );
    } else {
      this.props.history.push(
        `/inventories/${
          type === "booking" ? "inventory-booking-status" : "inventory-status"
        }/${inventory.ha_inventory_id}/${inventoryTitle}`
      );
    }
  };
}

export default OperationsBase;
