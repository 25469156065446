import React, { Component } from "react";
import { Table, Button, Popover, message, Badge } from "antd";
import { FileImageOutlined, StopTwoTone } from "@ant-design/icons";
import {
  currencyFormatter,
  dateFormatter,
  timeFormatter,
} from "../components/common/common-formatter";
import PlanInventoryEdit from "../components/plan/plan-inventory-edit";
import * as dayjs from "dayjs";
import IndianRupee from "../assets/icons/indian-rupee-coin-color.svg";
import PortalUser from "../iam/components/portal-user";
import { ajaxRequester } from "../services/ajax-requester";
import AdminApi from "../iam/api/admin-api";

class PlanCampaignInventoryBase extends Component {
  state = {};

  formRef = React.createRef();

  // Setting Default Data
  setData = () => {
    const { results, page } = this.props;
    let inventory_details =
      page === "plan"
        ? this.props.results.plan_inventory_details
        : this.props.results.campaign_inventory_details;

    inventory_details = inventory_details.map((x) => ({
      ...x,
      uuid: this.generateUUID(),
    }));

    let printing_install_charges = results?.printing_install_charges?.find(
      (x) => x.criteria_name === "all"
    );

    let rate_percentage =
      results?.discounting_criteria?.discount_criteria?.rate_percentage || 0;

    let lumpsum_rate =
      results?.discounting_criteria?.pricing_criteria?.lumpsum_rate || 0;

    let all_rate =
      results?.discounting_criteria?.sqft_rate_criteria?.all_rate || 0;

    let fl_rate =
      results?.discounting_criteria?.sqft_rate_criteria?.fl_rate || 0;

    let bl_rate =
      results?.discounting_criteria?.sqft_rate_criteria?.bl_rate || 0;

    let nl_rate =
      results?.discounting_criteria?.sqft_rate_criteria?.nl_rate || 0;

    let rate_per_unit =
      results?.rate_per_unit?.find((x) => x?.rate_per_unit)?.rate_per_unit || 0;

    this.formRef.current.setFieldsValue({
      print_fl_rate: printing_install_charges?.print_fl_rate || "",
      print_bl_rate: printing_install_charges?.print_bl_rate || "",
      install_fl_rate: printing_install_charges?.install_fl_rate || "",
      install_bl_rate: printing_install_charges?.install_bl_rate || "",
      discounting_per: rate_percentage || "",
      discounting_rate: lumpsum_rate || "",
      discounting_rate_all: all_rate || "",
      discounting_rate_fl: fl_rate || "",
      discounting_rate_bl: bl_rate || "",
      discounting_rate_nl: nl_rate || "",
      rate_per_unit: rate_per_unit || "",
    });

    let currentValues = {
      print_fl_rate: printing_install_charges?.print_fl_rate || 0,
      print_bl_rate: printing_install_charges?.print_bl_rate || 0,
      install_fl_rate: printing_install_charges?.install_fl_rate || 0,
      install_bl_rate: printing_install_charges?.install_bl_rate || 0,
      discounting_per: rate_percentage || 0,
      discounting_rate: lumpsum_rate || 0,
      discounting_rate_all: all_rate,
      discounting_rate_fl: fl_rate,
      discounting_rate_bl: bl_rate,
      discounting_rate_nl: nl_rate,
      rate_per_unit: rate_per_unit || 0,
    };

    let displayValues = {
      total_display_cost: results.total_display_cost || 0,
      total_printing_cost: results.total_printing_cost || 0,
      total_installing_cost: results.total_installing_cost || 0,
      total_without_tax:
        results.total_display_cost +
          results.total_printing_cost +
          results.total_installing_cost || 0,
      gst_amount: results.gst_amount || 0,
      gst_per: results.gst_per || 0,
      total_amount: results.total_amount || 0,
      base_rate_diff: results.base_rate_diff || 0,
      base_rate_diff_amt: results.base_rate_diff_amt || 0,
      ta_base_rate_diff: results?.ta_base_rate_diff || 0,
      ta_base_rate_diff_amt: results?.ta_base_rate_diff_amt || 0,
      total_sqft: results.total_sqft || 0,
      rate_per_sqft: results.rate_per_sqft || 0,
    };

    this.setState(
      {
        results: results,
        resultsDefault: results,
        inventory_details: inventory_details || inventory_details,
        inventory_details_default: inventory_details || inventory_details,
        inventory_details_default_filter:
          inventory_details || inventory_details,
        currentValues: currentValues,
        currentValues_default: currentValues,
        displayValues: displayValues,
        displayValues_default: displayValues,
        selectedRadio: rate_percentage
          ? "discounting"
          : lumpsum_rate
          ? "pricing"
          : all_rate || fl_rate || bl_rate || nl_rate
          ? "rateSQFT"
          : rate_per_unit
          ? "rateUnit"
          : "",
      },
      () => this.setColumns()
    );
  };

  // Setting Columns
  setColumns = () => {
    const { page } = this.props;

    let columns = [
      {
        title: "Sr",
        dataIndex: "sr",
        render: (text, record, key) => (
          <span style={{ whiteSpace: "nowrap" }}>
            {key + 1}
            {record?.campaign_status_reason && this.hanldeReason(record)}
          </span>
        ),
      },
      {
        title: "Type",
        dataIndex: "inventory_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        width: 110,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 340,
        render: (text, record) =>
          text +
          (record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Campaign Status",
        dataIndex: "campaign_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 148,
      },
      {
        title: "Available From",
        dataIndex: "available_from",
        // render: (text) =>
        //   dateFormatter(text) === dateFormatter(new Date())
        //     ? "Available"
        //     : dateFormatter(text),
        render: (text, record) =>
          record.inventory_type === "TA" &&
          dateFormatter(text) === dateFormatter(new Date()) ? (
            <PlanInventoryEdit
              key={text}
              value={text}
              record={record}
              column={"available_from"}
              handleInventoryEdit={this.handleInventoryAvailableDate}
            />
          ) : dateFormatter(text) === dateFormatter(new Date()) ? (
            "Available"
          ) : (
            dateFormatter(text)
          ),
        width: 120,
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        render: (text, record) => (
          <PlanInventoryEdit
            key={text}
            value={text}
            record={record}
            column={"start_date"}
            handleInventoryEdit={this.handleInventoryEditDates}
          />
        ),
        width: 100,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        render: (text, record) => (
          <PlanInventoryEdit
            key={text}
            value={text}
            record={record}
            column={"end_date"}
            handleInventoryEdit={this.handleInventoryEditDates}
          />
        ),
        width: 100,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text, record) => currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Base Rate",
        dataIndex: "base_rate",
        render: (text, record) =>
          record.inventory_type !== "TA" ? (
            currencyFormatter(text)
          ) : (
            <PlanInventoryEdit
              key={text}
              value={text}
              record={record}
              column={"base_rate"}
              handleInventoryEdit={this.handleInventoryEditBaseRate}
            />
          ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Monthly Cost",
        dataIndex: "monthly_rate",
        render: (text, record) => (
          <PlanInventoryEdit
            key={text}
            value={text}
            record={record}
            column={"monthly_rate"}
            handleInventoryEdit={this.handleInventoryEdit}
          />
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 130,
      },
      {
        title: "Cost",
        dataIndex: "rental_cost",
        render: (text, record) => (
          <PlanInventoryEdit
            key={text}
            value={text}
            record={record}
            column={"rental_cost"}
            handleInventoryEdit={this.handleInventoryEdit}
          />
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        render: (text, record) => (
          <PlanInventoryEdit
            key={text}
            value={text}
            record={record}
            column={"remarks"}
            handleInventoryEdit={this.handleInventoryRemarksEdit}
          />
        ),
      },
      {
        title: "Search Tags",
        dataIndex: "search_tags",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
    ];

    if (page === "plan") {
      columns.splice(13, 1, {
        title: "Booking Status",
        dataIndex: "booking_status",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            type="link"
            onClick={() => this.statusInventory(record, "booking")}
            style={{ marginRight: "8px" }}
          >
            {text === "B" ? (
              <React.Fragment>
                <Badge
                  count={record?.total_blocked_count}
                  size="small"
                  offset={[8, 0]}
                  style={{
                    background: "none",
                    color: "#2db7f5",
                    marginTop: "-4px",
                    marginRight: "4px",
                  }}
                  className="ant-btn-link"
                >
                  Blocked till{" "}
                  {record?.blocked_till_date &&
                    dateFormatter(record.blocked_till_date) +
                      " " +
                      timeFormatter(record.blocked_till_date)}
                </Badge>
              </React.Fragment>
            ) : text === "N" ? (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{ background: "none", color: "#2db7f5" }}
                className="ant-btn-link"
              >
                Not Available
              </Badge>
            ) : (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{ background: "none", color: "#2db7f5" }}
                className="ant-btn-link"
              >
                Available
              </Badge>
            )}
          </Button>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 142,
      });
      columns.splice(13, 0, {
        title: "Custom Photo",
        dataIndex: "custom_photo",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            icon={<FileImageOutlined onClick={this.handleCustomPhoto} />}
          />
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 130,
      });
      columns.splice(15, 0, {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      });
      columns.splice(16, 0, {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => text && <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      });

      columns.splice(20, 0, {
        title: "Days",
        dataIndex: "no_of_days",
        render: (text, record) =>
          dayjs(record?.end_date).diff(dayjs(record?.start_date), "days") + 1,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });
    }

    if (page === "campaign") {
      columns.splice(13, 0, {
        title: "Booking Status",
        dataIndex: "booking_status",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            type="link"
            onClick={() => this.statusInventory(record, "booking")}
          >
            {text === "B" ? (
              "Blocked till " +
              (record?.blocked_till_date &&
                dateFormatter(record.blocked_till_date) +
                  " " +
                  timeFormatter(record.blocked_till_date))
            ) : text === "N" ? (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{ background: "none", color: "#2db7f5" }}
                className="ant-btn-link"
              >
                Not Available
              </Badge>
            ) : (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{ background: "none", color: "#2db7f5" }}
                className="ant-btn-link"
              >
                Available
              </Badge>
            )}
          </Button>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 142,
      });

      columns.splice(18, 0, {
        title: "Days",
        dataIndex: "no_of_days",
        render: (text, record) =>
          dayjs(record?.end_date).diff(dayjs(record?.start_date), "days") + 1,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });
    }

    const filterColumns = [
      {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      { columnName: "sr", displayName: "Sr", hideFilter: true },
      { columnName: "inventory_type", displayName: "Type" },
      { columnName: "media_type", displayName: "Media" },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "district", displayName: "District" },
      { columnName: "city", displayName: "City" },
      { columnName: "area", displayName: "Area" },
      { columnName: "location", displayName: "Location" },
      { columnName: "size", displayName: "Size" },
      { columnName: "sqft", displayName: "SQFT" },
      { columnName: "light_type", displayName: "Light" },
      { columnName: "quantity", displayName: "Qty" },
      { columnName: "images", displayName: "Photo", hideFilter: true },
      { columnName: "card_rate", displayName: "Card Rate" },
      { columnName: "base_rate", displayName: "Base Rate" },
      { columnName: "booking_status", displayName: "Booking Status" },
      { columnName: "campaign_status", displayName: "Campaign Status" },
      {
        columnName: "custom_photo",
        displayName: "Custom Photo",
        hideFilter: true,
      },
      {
        columnName: "available_from",
        displayName: "Available From",
        hideFilter: true,
      },
      { columnName: "start_date", displayName: "Start Date", hideFilter: true },
      { columnName: "end_date", displayName: "End Date", hideFilter: true },
      { columnName: "monthly_rate", displayName: "Monthly Cost" },
      { columnName: "rental_cost", displayName: "Cost" },
      { columnName: "remarks", displayName: "Remarks" },
      {
        columnName: "search_tags",
        displayName: "Search Tags",
        hideFilter: true,
      },
    ];

    if (page === "plan") {
      filterColumns.splice(16, 0, {
        columnName: "display_name",
        displayName: "Display",
      });

      filterColumns.splice(17, 0, {
        columnName: "employee_id",
        displayName: "Employee",
      });

      filterColumns.splice(24, 0, {
        columnName: "no_of_days",
        displayName: "Days",
      });
    }

    if (page === "campaign") {
      filterColumns.splice(22, 0, {
        columnName: "no_of_days",
        displayName: "Days",
      });
    }

    columns.splice(2, 0, {
      title: "Supplier",
      dataIndex: "supplier_name",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    });

    filterColumns.splice(3, 0, {
      columnName: "supplier_name",
      displayName: "Supplier",
    });

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    let inventory_details = [...this.state.inventory_details];

    inventory_details.sort((a, b) =>
      sorter.order === "ascend"
        ? typeof a[sorter.field] !== "number"
          ? a[sorter.field]?.localeCompare(b[sorter.field]?.toLowerCase())
          : a[sorter.field] - b[sorter.field]
        : typeof b[sorter.field] !== "number"
        ? b[sorter.field]?.localeCompare(a[sorter.field]?.toLowerCase())
        : b[sorter.field] - a[sorter.field]
    );

    this.setState({ inventory_details });
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  onFinish = (value) => {
    let field = Object.keys(value)[0];

    let currentValues = this.state.currentValues;

    switch (field) {
      case "discounting_per":
        this.formRef.current.setFieldsValue({
          discounting_rate: "",
          discounting_rate_fl: "",
          discounting_rate_nl: "",
          discounting_rate_bl: "",
          discounting_rate_all: "",
          rate_per_unit: "",
        });

        currentValues.discounting_per = value.discounting_per;
        currentValues.discounting_rate = 0;
        currentValues.discounting_rate_fl = 0;
        currentValues.discounting_rate_nl = 0;
        currentValues.discounting_rate_bl = 0;
        currentValues.discounting_rate_all = 0;
        currentValues.rate_per_unit = 0;

        this.setState({ currentValues }, () =>
          value.discounting_per ? this.calDiscountPer() : this.calReset()
        );
        break;

      case "discounting_rate":
        this.formRef.current.setFieldsValue({
          discounting_per: "",
          discounting_rate_fl: "",
          discounting_rate_bl: "",
          discounting_rate_nl: "",
          discounting_rate_all: "",
          rate_per_unit: "",
        });
        currentValues.discounting_rate = value.discounting_rate;
        currentValues.discounting_per = 0;
        currentValues.discounting_rate_fl = 0;
        currentValues.discounting_rate_nl = 0;
        currentValues.discounting_rate_bl = 0;
        currentValues.discounting_rate_all = 0;
        currentValues.rate_per_unit = 0;

        this.setState({ currentValues }, () =>
          value.discounting_rate ? this.calDiscountRate() : this.calReset()
        );
        break;

      case "discounting_rate_all":
        this.formRef.current.setFieldsValue({
          discounting_rate: "",
          discounting_per: "",
          discounting_rate_fl: "",
          discounting_rate_bl: "",
          discounting_rate_nl: "",
          rate_per_unit: "",
        });

        currentValues.discounting_rate_all = value.discounting_rate_all;
        currentValues.discounting_rate = 0;
        currentValues.discounting_per = 0;
        currentValues.discounting_rate_fl = 0;
        currentValues.discounting_rate_nl = 0;
        currentValues.discounting_rate_bl = 0;
        currentValues.rate_per_unit = 0;

        this.setState({ currentValues }, () =>
          value.discounting_rate_all ? this.calSqftRateAll() : this.calReset()
        );
        break;

      case "discounting_rate_fl":
      case "discounting_rate_bl":
      case "discounting_rate_nl":
        this.formRef.current.setFieldsValue({
          discounting_rate: "",
          discounting_per: "",
          discounting_rate_all: "",
          rate_per_unit: "",
        });

        field === "discounting_rate_fl" &&
          (currentValues.discounting_rate_fl = value.discounting_rate_fl);

        field === "discounting_rate_bl" &&
          (currentValues.discounting_rate_bl = value.discounting_rate_bl);

        field === "discounting_rate_nl" &&
          (currentValues.discounting_rate_nl = value.discounting_rate_nl);

        currentValues.discounting_rate = 0;
        currentValues.discounting_per = 0;
        currentValues.discounting_rate_all = 0;
        currentValues.rate_per_unit = 0;

        this.setState({ currentValues }, () => this.calSqftRate());
        break;

      case "print_fl_rate":
      case "print_bl_rate":
      case "install_fl_rate":
      case "install_bl_rate":
        field === "print_fl_rate" &&
          (currentValues.print_fl_rate = value.print_fl_rate);

        field === "print_bl_rate" &&
          (currentValues.print_bl_rate = value.print_bl_rate);

        field === "install_fl_rate" &&
          (currentValues.install_fl_rate = value.install_fl_rate);

        field === "install_bl_rate" &&
          (currentValues.install_bl_rate = value.install_bl_rate);

        this.setState({ currentValues }, () => this.setPrintingMountingRates());
        break;

      case "rate_per_unit":
        this.formRef.current.setFieldsValue({
          discounting_rate: "",
          discounting_per: "",
          discounting_rate_fl: "",
          discounting_rate_bl: "",
          discounting_rate_nl: "",
          discounting_rate_all: "",
        });

        currentValues.rate_per_unit = value?.rate_per_unit;
        currentValues.discounting_rate = 0;
        currentValues.discounting_per = 0;
        currentValues.discounting_rate_fl = 0;
        currentValues.discounting_rate_nl = 0;
        currentValues.discounting_rate_bl = 0;
        currentValues.discounting_rate_all = 0;

        this.setState({ currentValues }, () =>
          value.rate_per_unit ? this.calRateUnit() : this.calReset()
        );
        break;

      default:
        break;
    }
  };

  // Update Summary
  updateSummary = () => {
    const { currentValues } = this.state;
    let displayValues = { ...this.state.displayValues_default };
    let inventory_details = [...this.state.inventory_details];

    let total_display_cost = 0;
    let base_rate = 0;
    let print_fl_rate = 0;
    let print_bl_rate = 0;
    let total_display_cost_ha = 0;
    let total_display_cost_ta = 0;
    let base_rate_ha = 0;
    let base_rate_ta = 0;

    let total_printing_cost = 0;
    let total_installing_cost = 0;

    inventory_details.map((x) => {
      return (
        x.light_type === "FL" && (print_fl_rate = print_fl_rate + x.sqft),
        x.light_type === "NL" && (print_fl_rate = print_fl_rate + x.sqft),
        x.light_type === "BL" && (print_bl_rate = print_bl_rate + x.sqft),
        (base_rate =
          base_rate +
          (x.base_rate / 30) * this.getInventoryDays(x.start_date, x.end_date)),
        (total_display_cost = total_display_cost + x.rental_cost),
        x.inventory_type === "HA" &&
          (base_rate_ha =
            base_rate_ha +
            (x.base_rate / 30) *
              this.getInventoryDays(x.start_date, x.end_date)),
        x.inventory_type === "HA" &&
          (total_display_cost_ha = total_display_cost_ha + x.rental_cost),
        x.inventory_type === "TA" &&
          (base_rate_ta =
            base_rate_ta +
            (x.base_rate / 30) *
              this.getInventoryDays(x.start_date, x.end_date)),
        x.inventory_type === "TA" &&
          (total_display_cost_ta = total_display_cost_ta + x.rental_cost),
        (total_printing_cost = total_printing_cost + x?.printing_cost),
        (total_installing_cost = total_installing_cost + x?.installing_cost)
      );
    });

    displayValues.print_fl_rate = print_fl_rate;
    displayValues.print_bl_rate = print_bl_rate;
    displayValues.total_sqft = print_fl_rate + print_bl_rate;
    displayValues.total_display_cost = total_display_cost;
    displayValues.total_display_cost_ha = total_display_cost_ha;
    displayValues.total_display_cost_ta = total_display_cost_ta;
    displayValues.total_printing_cost = total_printing_cost;
    displayValues.total_installing_cost = total_installing_cost;

    displayValues.total_without_tax =
      displayValues.total_display_cost +
      displayValues.total_printing_cost +
      displayValues.total_installing_cost;

    displayValues.gst_amount =
      (displayValues.total_display_cost * displayValues.gst_per) / 100 +
      (displayValues.total_printing_cost * displayValues.gst_per) / 100 +
      (displayValues.total_installing_cost * displayValues.gst_per) / 100;

    displayValues.total_amount =
      displayValues.total_without_tax + displayValues.gst_amount;

    // HA Calculations
    displayValues.base_rate_diff_amt =
      displayValues.total_display_cost_ha - base_rate_ha;

    displayValues.base_rate_diff =
      displayValues.total_display_cost_ha - base_rate_ha;

    displayValues.base_rate_diff =
      (displayValues.base_rate_diff / base_rate_ha) * 100;

    displayValues.base_rate_diff = displayValues.base_rate_diff.toFixed(2);

    // TA Calculations
    displayValues.ta_base_rate_diff_amt =
      displayValues.total_display_cost_ta - base_rate_ta;

    displayValues.ta_base_rate_diff =
      displayValues.total_display_cost_ta - base_rate_ta;

    displayValues.ta_base_rate_diff =
      (displayValues?.ta_base_rate_diff / base_rate_ta) * 100;

    displayValues.ta_base_rate_diff =
      displayValues?.ta_base_rate_diff.toFixed(2);

    this.setState({ displayValues, isChanged: true });
  };

  // Update All Inventories
  updateInventories = (isInventoryUpdated) => {
    const {
      discounting_per,
      discounting_rate,
      discounting_rate_fl,
      discounting_rate_bl,
      discounting_rate_nl,
      discounting_rate_all,
      rate_per_unit,
    } = this.state.currentValues;
    let inventory_details = [...this.state.inventory_details_default];

    if (discounting_rate) {
      let total_discount_rate = 0;

      inventory_details.map(
        (x) => (total_discount_rate = total_discount_rate + x.rental_cost)
      );

      inventory_details = inventory_details.map((x) => ({
        ...x,
        rental_cost: (x.rental_cost / total_discount_rate) * discounting_rate,
      }));

      inventory_details = inventory_details.map((x) => ({
        ...x,
        monthly_rate:
          (x.rental_cost / this.getInventoryDays(x.start_date, x.end_date)) *
          30,
      }));
    } else if (discounting_rate_all) {
      inventory_details = inventory_details.map((x) => ({
        ...x,
        monthly_rate: discounting_rate_all * x.sqft,
        rental_cost:
          ((discounting_rate_all * x.sqft) / 30) *
          this.getInventoryDays(x.start_date, x.end_date),
      }));
    } else if (
      discounting_rate_fl ||
      discounting_rate_bl ||
      discounting_rate_nl
    ) {
      inventory_details = inventory_details.map((x) => ({
        ...x,
        monthly_rate:
          x.light_type === "FL"
            ? discounting_rate_fl
              ? discounting_rate_fl * x.sqft
              : x.monthly_rate
            : x.light_type === "BL"
            ? discounting_rate_bl
              ? discounting_rate_bl * x.sqft
              : x.monthly_rate
            : x.light_type === "NL" && discounting_rate_nl
            ? discounting_rate_nl * x.sqft
            : x.monthly_rate,

        rental_cost:
          x.light_type === "FL"
            ? discounting_rate_fl
              ? ((discounting_rate_fl * x.sqft) / 30) *
                this.getInventoryDays(x.start_date, x.end_date)
              : x.rental_cost
            : x.light_type === "BL"
            ? discounting_rate_bl
              ? ((discounting_rate_bl * x.sqft) / 30) *
                this.getInventoryDays(x.start_date, x.end_date)
              : x.rental_cost
            : x.light_type === "NL" && discounting_rate_nl
            ? ((discounting_rate_nl * x.sqft) / 30) *
              this.getInventoryDays(x.start_date, x.end_date)
            : x.rental_cost,
      }));
    } else if (discounting_per || discounting_per === 0) {
      inventory_details = inventory_details.map((x) => ({
        ...x,
        monthly_rate: x.card_rate - (x.card_rate * discounting_per) / 100,
        rental_cost:
          (x.card_rate / 30) * this.getInventoryDays(x.start_date, x.end_date) -
          ((x.card_rate / 30) *
            this.getInventoryDays(x.start_date, x.end_date) *
            discounting_per) /
            100,
      }));
    } else if (rate_per_unit) {
      inventory_details = inventory_details.map((x) => ({
        ...x,
        monthly_rate: rate_per_unit * x.quantity,
        rental_cost:
          ((rate_per_unit * x.quantity) / 30) *
          this.getInventoryDays(x.start_date, x.end_date),
      }));
    } else if (isInventoryUpdated) {
      inventory_details = inventory_details.map((x) => ({
        ...x,
        monthly_rate: x.card_rate - (x.card_rate * discounting_per) / 100,
        rental_cost:
          (x.card_rate / 30) * this.getInventoryDays(x.start_date, x.end_date) -
          ((x.card_rate / 30) *
            this.getInventoryDays(x.start_date, x.end_date) *
            discounting_per) /
            100,
      }));
    } else {
    }

    this.setState(
      {
        inventory_details,
        inventory_details_default_filter: inventory_details,
      },
      () => this.updateSummary()
    );
  };

  // Calculate Discount Percentage
  calDiscountPer = () => {
    const { discounting_per } = this.state.currentValues;
    let inventory_details = [...this.state.inventory_details_default];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      monthly_rate: x.card_rate - (x.card_rate * discounting_per) / 100,
      rental_cost:
        (x.card_rate / 30) * this.getInventoryDays(x.start_date, x.end_date) -
        ((x.card_rate / 30) *
          this.getInventoryDays(x.start_date, x.end_date) *
          discounting_per) /
          100,
    }));

    this.setState(
      {
        inventory_details,
        inventory_details_default_filter: inventory_details,
      },
      () => this.updateSummary()
    );
  };

  // Calculate Lumpsum Rate
  calDiscountRate = () => {
    const { discounting_rate } = this.state.currentValues;

    let inventory_details = [...this.state.inventory_details_default];

    // Temparary Fixed
    let discounting_per = 0;
    inventory_details = inventory_details.map((x) => ({
      ...x,
      monthly_rate: x.card_rate - (x.card_rate * discounting_per) / 100,
      rental_cost:
        (x.card_rate / 30) * this.getInventoryDays(x.start_date, x.end_date) -
        ((x.card_rate / 30) *
          this.getInventoryDays(x.start_date, x.end_date) *
          discounting_per) /
          100,
    }));

    let total_discount_rate = 0;

    inventory_details.map(
      (x) => (total_discount_rate = total_discount_rate + x.rental_cost)
    );

    inventory_details = inventory_details.map((x) => ({
      ...x,
      rental_cost: (x.rental_cost / total_discount_rate) * discounting_rate,
    }));

    inventory_details = inventory_details.map((x) => ({
      ...x,
      monthly_rate:
        (x.rental_cost / this.getInventoryDays(x.start_date, x.end_date)) * 30,
    }));

    this.setState(
      {
        inventory_details,
        inventory_details_default_filter: inventory_details,
      },
      () => this.updateSummary()
    );
  };

  // Calculate SQFT Rate ALL
  calSqftRateAll = () => {
    const { discounting_rate_all } = this.state.currentValues;
    let inventory_details = [...this.state.inventory_details_default];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      monthly_rate: discounting_rate_all * x.sqft,
      rental_cost:
        ((discounting_rate_all * x.sqft) / 30) *
        this.getInventoryDays(x.start_date, x.end_date),
    }));

    this.setState(
      {
        inventory_details,
        inventory_details_default_filter: inventory_details,
      },
      () => this.updateSummary()
    );
  };

  // Calculate Rate Unit
  calRateUnit = () => {
    const { rate_per_unit } = this.state.currentValues;
    let inventory_details = [...this.state.inventory_details_default];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      monthly_rate: rate_per_unit * x.quantity,
      rental_cost:
        ((rate_per_unit * x.quantity) / 30) *
        this.getInventoryDays(x.start_date, x.end_date),
    }));

    this.setState(
      {
        inventory_details,
        inventory_details_default_filter: inventory_details,
      },
      () => this.updateSummary()
    );
  };

  // Calculate SQFT Rate Light type wise
  calSqftRate = () => {
    const { discounting_rate_fl, discounting_rate_bl, discounting_rate_nl } =
      this.state.currentValues;

    let inventory_details = [...this.state.inventory_details_default];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      monthly_rate:
        x.light_type === "FL"
          ? discounting_rate_fl
            ? discounting_rate_fl * x.sqft
            : x.monthly_rate
          : x.light_type === "BL"
          ? discounting_rate_bl
            ? discounting_rate_bl * x.sqft
            : x.monthly_rate
          : x.light_type === "NL" && discounting_rate_nl
          ? discounting_rate_nl * x.sqft
          : x.monthly_rate,

      rental_cost:
        x.light_type === "FL"
          ? discounting_rate_fl
            ? ((discounting_rate_fl * x.sqft) / 30) *
              this.getInventoryDays(x.start_date, x.end_date)
            : x.rental_cost
          : x.light_type === "BL"
          ? discounting_rate_bl
            ? ((discounting_rate_bl * x.sqft) / 30) *
              this.getInventoryDays(x.start_date, x.end_date)
            : x.rental_cost
          : x.light_type === "NL" && discounting_rate_nl
          ? ((discounting_rate_nl * x.sqft) / 30) *
            this.getInventoryDays(x.start_date, x.end_date)
          : x.rental_cost,
    }));

    this.setState(
      {
        inventory_details,
        inventory_details_default_filter: inventory_details,
      },
      () => this.updateSummary()
    );
  };

  // Reset All Calculations
  calReset = () => {
    let inventory_details = [...this.state.inventory_details_default];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      monthly_rate: x.card_rate,
      rental_cost:
        (x.card_rate / 30) * this.getInventoryDays(x.start_date, x.end_date),
    }));

    this.setState(
      {
        inventory_details,
        inventory_details_default_filter: inventory_details,
      },
      () => this.updateSummary()
    );
  };

  // Calculate Rate when new inventories addedd
  calAddInventory = (new_inventories) => {
    let new_added_inventories = new_inventories.map((x) => ({
      ...x,
      uuid: this.generateUUID(),
    }));

    if (this?.props?.page === "campaign") {
      new_added_inventories = new_added_inventories.map((x) => ({
        ...x,
        start_date:
          x?.booking_status === "A" || x?.booking_status === "B"
            ? dayjs()
            : x?.booking_status === "N"
            ? x?.available_from
            : x?.start_date,
      }));
    }

    let { currentValues } = this.state;
    currentValues.discounting_rate = 0;
    let inventory_details = [...this.state.inventory_details];
    inventory_details = inventory_details.concat(new_added_inventories);

    inventory_details = this.handleInventoryPrintingMounting(inventory_details);

    this.formRef.current.setFieldsValue({
      discounting_rate: "",
    });

    this.setState(
      {
        inventory_details_default: inventory_details,
        isDrawer: false,
        currentValues,
      },
      () => setCalc()
    );

    const setCalc = () => {
      if (currentValues.discounting_per) {
        this.onFinish({
          discounting_per: currentValues.discounting_per,
        });
      } else if (currentValues.discounting_rate_all) {
        this.onFinish({
          discounting_rate_all: currentValues.discounting_rate_all,
        });
      } else if (currentValues.rate_per_unit) {
        this.onFinish({
          rate_per_unit: currentValues.rate_per_unit,
        });
      } else if (currentValues.discounting_rate_fl) {
        this.onFinish({
          discounting_rate_fl: currentValues.discounting_rate_fl,
        });
      } else if (currentValues.discounting_rate_bl) {
        this.onFinish({
          discounting_rate_bl: currentValues.discounting_rate_bl,
        });
      } else if (currentValues.discounting_rate_nl) {
        this.onFinish({
          discounting_rate_nl: currentValues.discounting_rate_nl,
        });
      } else {
        this.setState(
          {
            inventory_details,
            inventory_details_default_filter: inventory_details,
          },
          () => this.updateSummary()
        );
      }
    };

    message.success("Inventories Added successfully");
  };

  // Calculate Rate when inventories deleted
  calDeleteInventory = () => {
    let inventory_details = [...this.state.inventory_details_default];

    let updatedSelectedRows = this.state.selectedRows || [];

    updatedSelectedRows = updatedSelectedRows.filter(
      (x) => x?.filter_row !== "hide"
    );

    inventory_details = inventory_details.filter(
      (x) =>
        x.uuid !==
        updatedSelectedRows.find(
          (y) =>
            y.uuid === x.uuid &&
            y?.campaign_status !== "Running" &&
            y?.campaign_status !== "Completed" &&
            y?.campaign_status !== "Expired" &&
            y?.campaign_status !== "Running - Pending for Approval"
        )?.uuid
    );

    this.formRef.current.setFieldsValue({
      discounting_rate: "",
    });

    let currentValues = this.state.currentValues;
    currentValues.discounting_rate = 0;

    this.setState(
      {
        inventory_details,
        inventory_details_default_filter: inventory_details,
        inventory_details_default: inventory_details,
        isDrawer: false,
        currentValues,
      },
      () => this.updateSummary()
    );

    updatedSelectedRows.map(
      (x) =>
        (x?.campaign_status === "Running" ||
          x?.campaign_status === "Completed" ||
          x?.campaign_status === "Expired" ||
          x?.campaign_status === "Running - Pending for Approval") &&
        message.error(
          "Running or Completed or Expired or Running - Pending for Approval inventories cannot be deleted."
        )
    );

    this.setState({ selectedRowKeys: [], selectedRows: [] });
  };

  // Calculate when individual inventory date change
  calInventoryDateChange = () => {};

  // Calculate when individual inventory cost and rental cost change
  calInventoryRateChange = () => {};

  // Handle Drawer
  handleDrawer = () => {
    this.setState({
      isDrawer: !this.state.isDrawer,
    });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    let inventory_details = [...this.state.inventory_details_default_filter];

    inventory_details = inventory_details.map((x) =>
      filterValue
        ? filterColumn.columnName !== "general"
          ? (
              filterColumn.columnName === "quantity" ||
              filterColumn.columnName === "sqft" ||
              filterColumn.columnName === "base_rate" ||
              filterColumn.columnName === "monthly_rate" ||
              filterColumn.columnName === "rental_cost"
                ? x[filterColumn.columnName].toString().includes(filterValue)
                : x[filterColumn.columnName]
                    ?.toLowerCase()
                    ?.includes(filterValue.toLowerCase())
            )
            ? {
                ...x,
                filter_row: "show",
              }
            : {
                ...x,
                filter_row: "hide",
              }
          : x.media_type.toLowerCase().includes(filterValue.toLowerCase()) ||
            x.inventory_code
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            x.city.toLowerCase().includes(filterValue.toLowerCase()) ||
            x.area.toLowerCase().includes(filterValue.toLowerCase()) ||
            x.location.toLowerCase().includes(filterValue.toLowerCase())
          ? {
              ...x,
              filter_row: "show",
            }
          : {
              ...x,
              filter_row: "hide",
            }
        : // If Filter Value Blank
          {
            ...x,
            filter_row: "show",
          }
    );

    this.setState({ inventory_details });
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  // handle Action Menu
  handleMenuClick = ({ key }) => {
    key === "add" && this.handleDrawer();
    key === "add-ta" && this.handleDrawer();
    key === "delete" && this.calDeleteInventory();
    key === "cos" && this.handleChangeOfSite();
    key === "dates" && this.handleEndDates();
    key === "add-ta"
      ? this.setState({ inventoryType: "tainventory" })
      : this.setState({ inventoryType: "hainventory" });
  };

  // Change radio of discount, pricing and sqft
  onChangeRadio = (e) => {
    this.setState({
      selectedRadio: e.target.value,
    });
  };

  // Handle Advanced Filters
  handleAdvanced = () => {
    this.setState(
      {
        isAdvanced: !this.state.isAdvanced,
        isDrawer: !this.state.isDrawer,
      },
      () => this.setPrintingMountingAdvancedRates()
    );
  };

  // Handle Advanced Printing
  handleAdvancedPrinting = (inventory_details) => {
    let currentValues = this.state.currentValues;

    this.formRef.current.setFieldsValue({
      print_fl_rate: "",
      print_bl_rate: "",
      install_fl_rate: "",
      install_bl_rate: "",
    });

    currentValues.print_fl_rate = 0;
    currentValues.print_bl_rate = 0;
    currentValues.install_fl_rate = 0;
    currentValues.install_bl_rate = 0;

    this.setState({
      inventory_details,
      inventory_details_default: inventory_details,
      inventory_details_default_filter: inventory_details,
      currentValues,
    });

    this.setState({ inventory_details }, () => this.handleAdvanced());
  };

  // Handle edit individual inventory rate changes
  handleInventoryEdit = (value, record, column) => {
    let inventory_details = [...this.state.inventory_details];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      monthly_rate:
        x.uuid === record.uuid
          ? column === "monthly_rate"
            ? value
            : (value / this.getInventoryDays(x.start_date, x.end_date)) * 30
          : x.monthly_rate,
      rental_cost:
        x.uuid === record.uuid
          ? column === "rental_cost"
            ? value
            : (value / 30) * this.getInventoryDays(x.start_date, x.end_date)
          : x.rental_cost,
    }));

    this.formRef.current.setFieldsValue({
      discounting_rate: "",
      discounting_per: "",
    });

    let currentValues = this.state.currentValues;
    currentValues.discounting_rate = 0;
    currentValues.discounting_per = 0;

    this.setState(
      {
        inventory_details,
        inventory_details_default: inventory_details,
        inventory_details_default_filter: inventory_details,
        currentValues,
      },
      () => this.updateSummary()
    );
  };

  // Handle edit individual inventory rate changes
  handleInventoryRemarksEdit = (value, record, column) => {
    let inventory_details = [...this.state.inventory_details];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      remarks: x.uuid === record.uuid ? value : x?.remarks,
    }));

    this.setState(
      {
        inventory_details,
        inventory_details_default: inventory_details,
        inventory_details_default_filter: inventory_details,
      },
      () => this.updateSummary()
    );
  };

  // Handle edit individual inventory base rate rate changes
  handleInventoryEditBaseRate = (value, record, column) => {
    let inventory_details = [...this.state.inventory_details];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      base_rate:
        x.uuid === record.uuid
          ? column === "base_rate"
            ? value
            : x.base_rate
          : x.base_rate,
    }));

    let currentValues = this.state.currentValues;

    this.setState(
      {
        inventory_details,
        inventory_details_default: inventory_details,
        inventory_details_default_filter: inventory_details,
        currentValues,
      },
      () => this.updateSummary()
    );
  };

  // Handle edit individual inventory data changes
  handleInventoryEditDates = (value, record, column) => {
    value = dayjs(value).format("YYYY-MM-DD");

    let inventory_details = [...this.state.inventory_details];

    let results = this.state.results;
    let resultsDefault = this.state.resultsDefault;

    inventory_details = inventory_details.map((x) => ({
      ...x,
      start_date:
        x.uuid === record.uuid
          ? column === "start_date"
            ? value
            : dayjs(value, "YYYY-MM-DD").diff(
                dayjs(x.start_date, "YYYY-MM-DD"),
                "days"
              ) < 0
            ? value
            : x.start_date
          : x.start_date,
      end_date:
        x.uuid === record.uuid
          ? column === "end_date"
            ? value
            : dayjs(value, "YYYY-MM-DD").diff(
                dayjs(x.end_date, "YYYY-MM-DD"),
                "days"
              ) > 0
            ? value
            : x.end_date
          : x.end_date,
      rental_cost:
        (x.monthly_rate / 30) *
        dayjs(x.end_date, "YYYY-MM-DD").diff(
          dayjs(x.start_date, "YYYY-MM-DD"),
          "days"
        ),
    }));

    inventory_details = inventory_details.map((x) => ({
      ...x,
      rental_cost:
        (x.monthly_rate / 30) * this.getInventoryDays(x.start_date, x.end_date),
    }));

    results.start_date = this.getPlanDates(inventory_details).start_date;
    results.end_date = this.getPlanDates(inventory_details).end_date;
    results.no_of_days = this.getPlanDates(inventory_details).no_of_days;

    resultsDefault.start_date = this.getPlanDates(inventory_details).start_date;
    resultsDefault.end_date = this.getPlanDates(inventory_details).end_date;
    resultsDefault.no_of_days = this.getPlanDates(inventory_details).no_of_days;

    this.formRef.current.setFieldsValue({
      discounting_rate: "",
    });

    let currentValues = this.state.currentValues;
    currentValues.discounting_rate = 0;

    this.setState(
      {
        inventory_details,
        inventory_details_default: inventory_details,
        inventory_details_default_filter: inventory_details,
        results,
        resultsDefault,
        currentValues,
      },
      () => this.updateSummary()
    );
  };

  // Return Plan Days based on all inventory start and end dates
  getPlanDates = (inventory_details) => {
    let plan_inventories = inventory_details;

    let start_date =
      plan_inventories.length > 0 ? plan_inventories[0].start_date : dayjs();
    let end_date =
      plan_inventories.length > 0 ? plan_inventories[0].end_date : dayjs();
    let no_of_days = 0;

    plan_inventories.map((x) =>
      dayjs(start_date).diff(dayjs(x.start_date), "days") > 0
        ? (start_date = x.start_date)
        : start_date
    );

    plan_inventories.map((x) =>
      dayjs(x.end_date).diff(dayjs(end_date), "days") > 0
        ? (end_date = x.end_date)
        : end_date
    );

    no_of_days = dayjs(end_date).diff(dayjs(start_date), "days") + 1;

    let plan_dates = {
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      end_date: dayjs(end_date).format("YYYY-MM-DD"),
      no_of_days: no_of_days,
    };

    return plan_dates;
  };

  // Updating Plan Details and Redirect to Details Sceen
  handleUpdates = () => {
    let results = this.state.results;
    let currentValues = this.state.currentValues;
    let displayValues = this.state.displayValues;
    let inventory_details = this.state.inventory_details;

    results.start_date = this.getPlanDates(inventory_details).start_date;
    results.end_date = this.getPlanDates(inventory_details).end_date;
    results.no_of_days = this.getPlanDates(inventory_details).no_of_days;
    results.campaign_inventory_details = inventory_details;
    results.plan_inventory_details = inventory_details;
    results.total_display_cost = displayValues.total_display_cost;
    results.total_printing_cost = displayValues.total_printing_cost;
    results.total_installing_cost = displayValues.total_installing_cost;
    results.total_without_tax = displayValues.total_without_tax;
    results.gst_amount = displayValues.gst_amount;
    results.gst_per = displayValues.gst_per;
    results.total_amount = displayValues.total_amount;
    results.base_rate_diff = Math.round(displayValues?.base_rate_diff) || 0;
    results.base_rate_diff_amt = displayValues.base_rate_diff_amt || 0;
    results.ta_base_rate_diff =
      Math.round(displayValues?.ta_base_rate_diff) || 0;
    results.ta_base_rate_diff_amt = displayValues?.ta_base_rate_diff_amt || 0;
    results.total_sqft = displayValues.total_sqft;
    results.rate_per_sqft = displayValues.rate_per_sqft;
    results.printing_install_charges = [
      {
        criteria_name: "all",
        criteria_value: "inventories",
        print_fl_rate: currentValues.print_fl_rate || 0,
        print_bl_rate: currentValues.print_bl_rate || 0,
        install_fl_rate: currentValues.install_fl_rate || 0,
        install_bl_rate: currentValues.install_bl_rate || 0,
      },
    ];
    results.discount_criteria = [
      {
        criteria_name: "all",
        criteria_value: "inventories",
        rate_percentage: currentValues.discounting_per,
        rate_sqft: currentValues.discounting_rate,
      },
    ];
    results.discounting_criteria = {
      discount_criteria: {
        rate_percentage: currentValues.discounting_per,
      },
      pricing_criteria: {
        // lumpsum_rate: currentValues.discounting_rate,
      },
      sqft_rate_criteria: {
        all_rate: currentValues.discounting_rate_all,
        fl_rate: currentValues.discounting_rate_fl,
        bl_rate: currentValues.discounting_rate_bl,
        nl_rate: currentValues.discounting_rate_nl,
      },
    };
    results.rate_per_unit = [
      { media_type: "all", rate_per_unit: currentValues?.rate_per_unit },
    ];
    this.props.handleInventory(results);
    this.setState({
      isChanged: false,
      isSaveLoading: !this.state.isSaveLoading,
    });
  };

  // Return days based on start date and end dates
  getInventoryDays = (start_date, end_date) => {
    let no_of_days = dayjs(end_date, "YYYY-MM-DD").diff(
      dayjs(start_date, "YYYY-MM-DD"),
      "days"
    );
    return no_of_days + 1;
  };

  // Get Multi Inventory Details when expanded
  getMultiDetails = (record) => {
    const inventories = record.ha_base_inventories;

    let columns = [
      {
        title: "Type",
        dataIndex: "ha_inventory_id",
        render: (text) => (text ? "HA" : "TA"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
      },
      {
        title: "Light",
        dataIndex: "light_type",
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Booking Status",
        dataIndex: "booking_status",
        render: (text) => (text === "B" ? "Blocked" : "-"),
      },
    ];

    let data = inventories;

    return (
      <Table
        columns={columns}
        rowKey={(record) => record.ha_inventory_id}
        dataSource={data}
        pagination={false}
      />
    );
  };

  // Handle Photos SlideShoe
  handlePhotos = (record) => {
    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record ? record?.ha_images : "",
      photosTitle: record ? record : "",
    });
  };

  // Handle Custom Photos
  handleCustomPhoto = () => {
    this.setState({
      isCustomPhoto: !this.state.isCustomPhoto,
    });
  };

  hanldeReason = (record) => {
    let reason = record.campaign_status_reason;
    let data = "";
    let color = "";

    if (reason === "N") {
      data = "Not Available";
      color = "purple";
    }
    if (reason === "L") {
      data = "";
      color = "cyan";
    }
    if (reason === "B") {
      data = "";
      color = "orange";
    }

    let popover = (
      <Popover content={data}>
        {reason === "N" && <StopTwoTone style={{ marginLeft: "4px" }} />}
        {(reason === "L" || reason === "B") && (
          <img width="18px" src={IndianRupee} style={{ marginLeft: "4px" }} />
        )}
      </Popover>
    );
    return popover;
  };

  // Handle Popup
  handleModal = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  handleChangeOfSite = () => {
    this.handleModal();
  };

  handleChangeOfSiteDate = (cos_date, type) => {
    this.setState(
      {
        isModal: !this.state.isModal,
        isChangeOfSite: true,
        cos_date,
        inventoryType: type === "ha" ? "hainventory" : "tainventory",
      },
      () => this.handleDrawer()
    );
  };

  changeOfSite = (inventories) => {
    let selected_inventory = this.state.selectedRows[0];
    let new_inventory = inventories[0];
    let cos_date = this.state.cos_date;
    this.setState(
      {
        isChangeOfSite: false,
        isDrawer: false,
      },
      () =>
        this.props.handleChangeOfSite(
          selected_inventory,
          new_inventory,
          cos_date
        )
    );
  };

  handleExpiredInventoriesToggle = (e) => {
    let inventory_details = [...this.state.inventory_details_default_filter];

    inventory_details = inventory_details.map((x) =>
      e
        ? !x?.campaign_status?.includes("Completed") &&
          !x?.campaign_status?.includes("Expired")
          ? {
              ...x,
              filter_row: "show",
            }
          : { ...x, filter_row: "hide" }
        : {
            ...x,
            filter_row: "show",
          }
    );

    this.setState({ inventory_details });
  };

  handleAdjustCampaignDatesToggle = (e) => {
    this.props.handleAdjustCampaignDatesToggle(e);
    this.setState({ auto_adjust_campaign_dates: e });
  };

  handleAdjustPlanDatesToggle = (e) => {
    this.props.handleAdjustPlanDatesToggle(e);
    this.setState({ auto_adjust_site_start_dates: e });
  };

  handleRotational = (e) => {
    this.props.handleRotational(e);
    this.setState({ is_rotational: e });
  };

  // Setup Background Colors
  setColors = (record) => {
    return record.campaign_status === "Completed"
      ? !record.filter_row
        ? "row-bg-redgray"
        : record.filter_row === "show"
        ? "row-bg-redgray"
        : "row-bg-redgray hide-filtered-row"
      : record.campaign_status === "Running"
      ? !record.filter_row
        ? "row-bg-yellow"
        : record.filter_row === "show"
        ? "row-bg-yellow"
        : "row-bg-yellow hide-filtered-row"
      : // Status not
      !record.filter_row
      ? ""
      : record.filter_row === "show"
      ? ""
      : "hide-filtered-row";
  };

  // Setup Filters
  setFilter = (record) => {
    return !record.filter_row
      ? ""
      : record.filter_row === "show"
      ? ""
      : "hide-filtered-row";
  };

  generateUUID = () => {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  handleEndDates = () => {
    this.setState({ isEndDatesModal: !this.state.isEndDatesModal });
  };

  changeEndDates = (extend_date, selectedRows, selectedRadio) => {
    this.setState({ isEndDatesModal: !this.state.isEndDatesModal });

    let key = 0;
    if (selectedRows && selectedRows.length > 0) {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        this.handleInventoryEditDates(
          selectedRadio === "extend_by_date" ? extend_date : extend_date[key],
          selectedRows[key],
          "end_date"
        );
        key = key + 1;

        if (key === selectedRows?.length) {
          clearInterval(this.intervalId);
        }
      }, 1);
    }

    // selectedRows &&
    //   selectedRows.length > 0 &&
    //   selectedRows.map((x, key) =>
    //     this.handleInventoryEditDates(
    //       selectedRadio === "extend_by_date" ? extend_date : extend_date[key],
    //       x,
    //       "end_date"
    //     )
    //   );
  };

  // Handle edit individual inventory data changes
  handleInventoryAvailableDate = (value, record, column) => {
    const ha_inventory_id = record.ha_inventory_id;
    let available_from = dayjs(value).format("YYYY-MM-DD");

    var reqUrl = "tainventory/update/availabledate";
    var reqObj = {
      request: {
        ha_inventory_id: ha_inventory_id,
        available_from: available_from,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      message.success("Available From Date Updated successfully");
    });
  };

  // Handle individual inventory print and installation cost
  handleInventoryPrintingMounting = (inventories) => {
    let inventory_details = inventories;
    let currentValues = this.state.currentValues;

    inventory_details = inventory_details.map((x) => ({
      ...x,
      printing_cost:
        x.light_type === "BL"
          ? currentValues?.print_bl_rate
            ? x.sqft * currentValues?.print_bl_rate
            : x.printing_cost || 0
          : currentValues?.print_fl_rate
          ? x.sqft * currentValues?.print_fl_rate
          : x.printing_cost || 0,
      installing_cost:
        x.light_type === "BL"
          ? currentValues?.install_bl_rate
            ? x.sqft * currentValues?.install_bl_rate
            : x.installing_cost || 0
          : currentValues?.install_fl_rate
          ? x.sqft * currentValues?.install_fl_rate
          : x.installing_cost || 0,
    }));

    return inventory_details;
  };

  // Redirect to inventory status screen
  statusInventory = (inventory, type) => {
    let inventoryTitle =
      inventory.inventory_code +
      " - " +
      inventory.city +
      ", " +
      inventory.location +
      " - " +
      inventory.width +
      "x" +
      inventory.height;

    if (type === "booking") {
      window.open(
        `/#/inventories/${
          type === "booking" ? "inventory-booking-status" : "inventory-status"
        }/${inventory.ha_inventory_id}/${inventoryTitle}`,
        "_blank"
      );
    } else {
      this.props.history.push(
        `/inventories/${
          type === "booking" ? "inventory-booking-status" : "inventory-status"
        }/${inventory.ha_inventory_id}/${inventoryTitle}`
      );
    }
  };

  // Set Default Columns
  setDefaultColumns = (columns) => {
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({ columnsSettings: columnsSettings || {} }, () =>
          this.applyDefaultColums(columns)
        );
      })
      .catch((nothing) => {});
  };

  // Apply Default Columns
  applyDefaultColums = (columns) => {
    let { columnsSettings, columnsDefault, screen } = this.state;
    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen];

      if (colConfig && colConfig.length > 1) {
        columns = columnsDefault.filter(({ title }) =>
          colConfig.some((exclude) => exclude.title === title)
        );
      }
      this.setState({ columns });
    }
  };

  // Get Active and Inactive Inventories count
  getTotalInventoriesCount = () => {
    const { inventory_details_default_filter } = this.state;

    let totalInventories =
      inventory_details_default_filter &&
      inventory_details_default_filter.length;

    let activeInventories =
      inventory_details_default_filter &&
      inventory_details_default_filter.filter(
        (x) =>
          x?.campaign_status !== "Completed" && x?.campaign_status !== "Expired"
      ).length;

    totalInventories = (activeInventories || 0) + "/" + (totalInventories || 0);

    return totalInventories;
  };

  // Handle individual inventory print and installation cost
  setPrintingMountingRates = () => {
    let inventory_details = [...this.state.inventory_details];
    let currentValues = this.state.currentValues;

    inventory_details = inventory_details.map((x) => ({
      ...x,
      printing_cost:
        x.light_type === "BL"
          ? currentValues?.print_bl_rate || currentValues?.print_bl_rate === 0
            ? x.sqft * currentValues?.print_bl_rate
            : x.printing_cost || 0
          : currentValues?.print_fl_rate || currentValues?.print_fl_rate === 0
          ? x.sqft * currentValues?.print_fl_rate
          : x.printing_cost || 0,
      installing_cost:
        x.light_type === "BL"
          ? currentValues?.install_bl_rate ||
            currentValues?.install_bl_rate === 0
            ? x.sqft * currentValues?.install_bl_rate
            : x.installing_cost || 0
          : currentValues?.install_fl_rate ||
            currentValues?.install_fl_rate === 0
          ? x.sqft * currentValues?.install_fl_rate
          : x.installing_cost || 0,
    }));

    this.setState({ inventory_details }, () => this.updateSummary());
  };

  // Handle Advanced individual inventory print and installation cost
  setPrintingMountingAdvancedRates = () => {
    let inventory_details = [...this.state.inventory_details];
    let currentValues = this.state.currentValues;

    inventory_details = inventory_details.map((x) => ({
      ...x,
      printing_cost:
        x.light_type === "BL"
          ? currentValues?.print_bl_rate
            ? x.sqft * currentValues?.print_bl_rate
            : x.printing_cost || 0
          : currentValues?.print_fl_rate
          ? x.sqft * currentValues?.print_fl_rate
          : x.printing_cost || 0,
      installing_cost:
        x.light_type === "BL"
          ? currentValues?.install_bl_rate
            ? x.sqft * currentValues?.install_bl_rate
            : x.installing_cost || 0
          : currentValues?.install_fl_rate
          ? x.sqft * currentValues?.install_fl_rate
          : x.installing_cost || 0,
    }));

    this.setState({ inventory_details }, () => this.updateSummary());
  };
}

export default PlanCampaignInventoryBase;
