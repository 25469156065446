import React, { useState, useEffect, Children } from "react";
import { Link } from "react-router-dom";
import { Button, Menu, Dropdown, Tag } from "antd";
import AdminApi from "../../iam/api/admin-api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ajaxRequester } from "../../services/ajax-requester";

const AppMainMenu = (props) => {
  const { theme } = props;
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const [enable_dashboard, setEnableDashboard] = useState(false);

  useEffect(() => {
    AdminApi.getAuthToken()
      .then((token) => setToken(token))
      .catch((e) => {});
  }, []);

  const checkActive = () => {
    let page = props.history.location.pathname;

    let activeKey = "customers:1";

    if (page.includes("customers")) {
      activeKey = "customers:1";
    }

    if (page.includes("pointofcontacts")) {
      activeKey = "customers:2";
    }

    if (page.includes("inventories")) {
      activeKey = "inventory-management:1";
    }

    if (page.includes("ta-inventories")) {
      activeKey = "inventory-management:2";
    }

    if (page.includes("inventories-map")) {
      activeKey = "inventory-management:3";
    }

    if (page.includes("inventories-contract")) {
      activeKey = "inventory-management:4";
    }

    if (page.includes("inventories-deleted")) {
      activeKey = "inventory-management:5";
    }

    if (page.includes("inventories-import")) {
      activeKey = "inventory-management:6";
    }

    if (page.includes("ta-inventories-import")) {
      activeKey = "inventory-management:7";
    }

    if (page.includes("branch-management")) {
      activeKey = "inventory-management:8";
    }

    if (page.includes("masters")) {
      activeKey = "inventory-management:9";
    }

    if (page.includes("ta-inventory-add")) {
      activeKey = "inventory-management:2";
    }

    if (page.includes("inventories-master")) {
      activeKey = "inventory-master:1";
    }

    if (page.includes("ta-inventories-master")) {
      activeKey = "inventory-master:2";
    }

    if (page.includes("inventories-tracking")) {
      activeKey = "inventory-master:3";
    }

    if (page.includes("city-update")) {
      activeKey = "inventory-master:4";
    }

    if (page.includes("plans")) {
      activeKey = "plans:1";
    }

    if (page.includes("campaign")) {
      activeKey = "campaign:1";
    }

    if (page.includes("operations")) {
      activeKey = "operations:1";
    }

    if (page.includes("waiting-for-flex")) {
      activeKey = "operations:1";
    }

    if (page.includes("change-of-site")) {
      activeKey = "operations:2";
    }

    if (page.includes("pending-mounting")) {
      activeKey = "operations:3";
    }

    if (page.includes("monitoring")) {
      activeKey = "operations:4";
    }

    if (page.includes("pending-unmounting")) {
      activeKey = "operations:6";
    }
    if (page.includes("mounting-unmounting")) {
      activeKey = "operations:8";
    }

    if (page.includes("pending-unmounting-photo")) {
      activeKey = "operations:5";
    }

    if (page.includes("stock-management")) {
      activeKey = "operations:7";
    }

    if (page.includes("campaign-operations")) {
      activeKey = "campaign:1";
    }

    if (page.includes("sales-estimate")) {
      activeKey = "sales-estimate:1";
    }

    if (page.includes("sales-estimate-list")) {
      activeKey = "sales-estimate:1";
    }

    if (page.includes("approve-reject-invoices")) {
      activeKey = "sales-estimate:2";
    }

    if (page.includes("invoice-date-number")) {
      activeKey = "sales-estimate:3";
    }

    if (page.includes("invoice-list")) {
      activeKey = "sales-estimate:4";
    }

    if (page.includes("pending-invoices")) {
      activeKey = "sales-estimate:5";
    }

    if (page.includes("credit-notes")) {
      activeKey = "sales-estimate:6";
    }

    if (page.includes("journal-voucher")) {
      activeKey = "sales-estimate:7";
    }

    if (page.includes("proforma-invoices")) {
      activeKey = "sales-estimate:8";
    }

    if (page.includes("photobucket-list")) {
      activeKey = "photobucket:1";
    }
    if (page.includes("reviewer-list")) {
      activeKey = "photobucket:2";
    }

    if (page.includes("payout-estimate")) {
      activeKey = "payout-estimate:1";
    }
    if (page.includes("transactions")) {
      activeKey = "transactions:1";
    }
    if (page.includes("transactions-approval")) {
      activeKey = "transactions:2";
    }
    if (page.includes("one-time-transactions")) {
      activeKey = "transactions:3";
    }
    if (page.includes("payee-list")) {
      activeKey = "masters:1";
    }
    if (page.includes("sister-company-list")) {
      activeKey = "masters:2";
    }
    if (page.includes("estimate-type-list")) {
      activeKey = "masters:3";
    }
    if (page.includes("dashboard")) {
      activeKey = "dashboard:10001";
    }
    if (page.includes("occupancy")) {
      activeKey = "dashboard:10002";
    }
    if (page.includes("roi")) {
      activeKey = "dashboard:10004";
    }

    return activeKey;
  };

  const currentUserRoles = AdminApi.getCurrentUser().Roles;

  const isAccess = (role) => {
    let isAccess = currentUserRoles.includes("su")
      ? true
      : currentUserRoles.includes(role);

    return isAccess;
  };

  const menuItemsObj = [
    ...(isAccess("su")
      ? enable_dashboard
        ? [
            {
              key: "10001",
              label: "Dashboard",
              children: [
                {
                  key: "dashboard:10001",
                  label: <Link to="/dashboard/sales">Sales</Link>,
                },
              ],
            },
          ]
        : []
      : []),
    ...(isAccess("ad") || isAccess("sr")
      ? [
          {
            key: "1",
            label: "Customers",
            children: [
              {
                key: "customers:1",
                label: <Link to="/customers/customers">Customer List</Link>,
              },
              {
                key: "customers:2",
                label: (
                  <Link to="/customers/pointofcontacts">Point Of Contacts</Link>
                ),
              },
            ],
          },
        ]
      : []),
    ...(isAccess("ad") || isAccess("pl")
      ? [
          {
            key: "2",
            label: "Inventory Management",
            children: [
              {
                key: "inventory-management:1",
                label: (
                  <Link to="/inventories/inventories">HA Inventory List</Link>
                ),
              },
              {
                key: "inventory-management:2",
                label: (
                  <Link to="/inventories/ta-inventories">
                    TA Inventory List
                  </Link>
                ),
              },
              {
                key: "inventory-management:3",
                label: (
                  <Link to="/inventories/inventories-map">Inventories Map</Link>
                ),
              },
              {
                key: "inventory-management:4",
                label: (
                  <Link to="/inventories/inventories-contract">
                    Contract List
                  </Link>
                ),
              },
              {
                key: "inventory-management:5",
                label: (
                  <Link to="/inventories/inventories-deleted">
                    Deleted Inventory List
                  </Link>
                ),
              },
              {
                key: "inventory-management:6",
                label: (
                  <Link to="/inventories/inventories-import">
                    Import HA Inventory
                  </Link>
                ),
              },
              {
                key: "inventory-management:7",
                label: (
                  <Link to="/inventories/ta-inventories-import">
                    Import TA Inventory
                  </Link>
                ),
              },
              {
                key: "inventory-management:8",
                label: (
                  <Link to="/inventories/branch-management">
                    Branch Management
                  </Link>
                ),
              },
              {
                key: "inventory-management:9",
                label: <Link to="/masters">Masters</Link>,
              },
            ],
          },
        ]
      : []),
    {
      key: "3",
      label: "Inventory Master",
      children: [
        {
          key: "inventory-master:1",
          label: (
            <Link to="/inventories/inventories-master">
              HA Inventory Master List
            </Link>
          ),
        },
        {
          key: "inventory-master:2",
          label: (
            <Link to="/inventories/ta-inventories-master">
              TA Inventory Master List
            </Link>
          ),
        },
        {
          key: "inventory-master:3",
          label: <Link to="/inventories/inventories-tracking">Track List</Link>,
        },
        {
          key: "inventory-master:4",
          label: <Link to="/inventories/city-update">City Update</Link>,
        },
      ],
    },
    ...(isAccess("ad") || isAccess("sr") || isAccess("pl")
      ? [
          {
            key: "4",
            label: "Plan",
            children: [
              {
                key: "plans:1",
                label: <Link to="/plans/plans">Plan List</Link>,
              },
            ],
          },
        ]
      : []),
    ...(isAccess("ad") || isAccess("sr")
      ? [
          {
            key: "5",
            label: "Campaign",
            children: [
              {
                key: "campaign:1",
                label: <Link to="/campaigns/campaigns">Campaign List</Link>,
              },
            ],
          },
        ]
      : []),
    ...(isAccess("ad") ||
    isAccess("sr") ||
    isAccess("bm") ||
    isAccess("ph") ||
    isAccess("pl")
      ? [
          {
            key: "6",
            label: "Operations",
            children: [
              ...(isAccess("ad") ||
              isAccess("sr") ||
              isAccess("bm") ||
              isAccess("pl")
                ? [
                    {
                      key: "operations:1",
                      label: (
                        <Link to="/operations/waiting-for-flex">
                          Waiting for Flex
                        </Link>
                      ),
                    },
                    {
                      key: "operations:2",
                      label: (
                        <Link to="/operations/change-of-site">
                          Change of Site List
                        </Link>
                      ),
                    },
                    {
                      key: "operations:3",
                      label: (
                        <Link to="/operations/pending-mounting">Mounting</Link>
                      ),
                    },
                  ]
                : []),
              ...(isAccess("ad") ||
              isAccess("sr") ||
              isAccess("bm") ||
              isAccess("ph") ||
              isAccess("pl")
                ? [
                    {
                      key: "operations:4",
                      label: (
                        <Link to="/operations/monitoring">Monitoring</Link>
                      ),
                    },
                  ]
                : []),
              ...(isAccess("ad") ||
              isAccess("sr") ||
              isAccess("bm") ||
              isAccess("pl")
                ? [
                    {
                      key: "operations:5",
                      label: (
                        <Link to="/operations/pending-unmounting-photo">
                          Unmounting Photo
                        </Link>
                      ),
                    },
                    {
                      key: "operations:6",
                      label: (
                        <Link to="/operations/pending-unmounting">
                          Unmounted Flex Receive
                        </Link>
                      ),
                    },
                    {
                      key: "operations:8",
                      label: (
                        <Link to="/operations/mounting-unmounting">
                          Mounting / Unmounting
                        </Link>
                      ),
                    },
                    ...(isAccess("ad") || isAccess("bm")
                      ? [
                          {
                            key: "operations:7",
                            label: (
                              <Link to="/operations/stock-management">
                                Flex Stock Management
                              </Link>
                            ),
                          },
                        ]
                      : []),
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isAccess("ad") || isAccess("sr") || isAccess("ac")
      ? [
          {
            key: "7",
            label: "Sales Estimate",
            children: [
              ...(isAccess("ad") || isAccess("sr")
                ? [
                    {
                      key: "sales-estimate:1",
                      label: (
                        <Link to="/sales-estimate/sales-estimate-list">
                          Sales Estimate List
                        </Link>
                      ),
                    },
                  ]
                : []),
              {
                key: "sales-estimate:5",
                label: (
                  <Link to="/sales-estimate/pending-invoices">
                    Pending Invoices
                  </Link>
                ),
              },
              {
                key: "sales-estimate:8",
                label: (
                  <Link to="/sales-estimate/proforma-invoices">
                    Proforma Invoices
                  </Link>
                ),
              },
              {
                key: "sales-estimate:2",
                label: (
                  <Link to="/sales-estimate/approve-reject-invoices">
                    Approve Reject Invoices
                  </Link>
                ),
              },
              ...(isAccess("ad") || isAccess("ac") || isAccess("sr")
                ? [
                    {
                      key: "sales-estimate:3",
                      label: (
                        <Link to="/sales-estimate/invoice-date-number">
                          Invoice Date and Number
                        </Link>
                      ),
                    },
                  ]
                : []),
              {
                key: "sales-estimate:4",
                label: (
                  <Link to="/sales-estimate/invoice-list">Invoice List</Link>
                ),
              },
              {
                key: "sales-estimate:6",
                label: (
                  <Link to="/sales-estimate/credit-notes">Credit Notes</Link>
                ),
              },
              {
                key: "sales-estimate:7",
                label: (
                  <Link to="/sales-estimate/journal-voucher">
                    Journal Voucher (JV)
                  </Link>
                ),
              },
            ],
          },
        ]
      : []),
    ...(isAccess("ad") ||
    isAccess("sr") ||
    isAccess("ac") ||
    isAccess("bm") ||
    isAccess("ph") ||
    isAccess("pr")
      ? [
          {
            key: "15",
            label: "Photolibrary",
            children: [
              {
                key: "photobucket:1",
                label: <Link to="/photobucket-list">Photolibrary List</Link>,
              },
              ...(isAccess("ad") || isAccess("pr")
                ? [
                    {
                      key: "photobucket:2",
                      label: <Link to="/reviewer-list">Reviewer List</Link>,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    {
      key: "9",
      label: `Hi, ${
        AdminApi.getCurrentUser().FirstName ??
        AdminApi.getCurrentUser().UserName
      }`,
      children: [
        ...(isAccess("su")
          ? [
              {
                key: "settings",
                label: <Link to={"/settings"}>Settings</Link>,
              },
            ]
          : []),
        {
          key: "logout",
          label: "Logout",
        },
        {
          key: "copytoken",
          label: (
            <CopyToClipboard
              text={token ?? ""}
              onCopy={() => {
                message.success("Copied token to clipboard");
              }}
            >
              <span>Copy Token</span>
            </CopyToClipboard>
          ),
        },
      ],
    },
  ];

  const menuItemsPurchaseObj = [
    {
      key: "16",
      label: "Transactions",
      children: [
        {
          key: "transactions:2",
          label: (
            <Link to="/purchase/transactions-approval">
              Transactions Approval
            </Link>
          ),
        },
        ...(isAccess("pa") || isAccess("pt")
          ? [
              {
                key: "transactions:1",
                label: (
                  <Link to="/purchase/transactions">
                    Scheduled Transactions
                  </Link>
                ),
              },
              {
                key: "transactions:3",
                label: (
                  <Link to="/purchase/one-time-transactions">
                    One Time Transactions
                  </Link>
                ),
              },
            ]
          : []),
      ],
    },
    ...(isAccess("pa")
      ? [
          {
            key: "17",
            label: "Payout Estimate",
            children: [
              {
                key: "payout-estimate:1",
                label: (
                  <Link to="/purchase/payout-estimate">Payout Estimate</Link>
                ),
              },
            ],
          },
          {
            key: "18",
            label: "Masters",
            children: [
              {
                key: "masters:1",
                label: <Link to="/purchase/payee-list">Payee List</Link>,
              },
              {
                key: "masters:2",
                label: (
                  <Link to="/purchase/sister-company-list">
                    Sister Company Master
                  </Link>
                ),
              },
              {
                key: "masters:3",
                label: (
                  <Link to="/purchase/estimate-type-list">
                    Estimate Type Master
                  </Link>
                ),
              },
            ],
          },
        ]
      : []),
    {
      key: "9",
      label: `Hi, ${
        AdminApi.getCurrentUser().FirstName ??
        AdminApi.getCurrentUser().UserName
      }`,
      children: [
        ...(isAccess("su")
          ? [
              {
                key: "settings",
                label: <Link to={"/settings"}>Settings</Link>,
              },
            ]
          : []),
        {
          key: "logout",
          label: "Logout",
        },
        {
          key: "copytoken",
          label: (
            <CopyToClipboard
              text={token ?? ""}
              onCopy={() => {
                message.success("Copied token to clipboard");
              }}
            >
              <span>Copy Token</span>
            </CopyToClipboard>
          ),
        },
      ],
    },
  ];

  const handleMenuToggle = () => {
    setActiveModule(activeModule === "sales" ? "purchase" : "sales");
    navigate(
      !props.history.location.pathname.includes("purchase")
        ? "/purchase/transactions-approval"
        : "/"
    );
  };

  const [activeModule, setActiveModule] = useState(
    !props.location.pathname.includes("purchase") ? "sales" : "purchase"
  );

  const checkRedirect = () => {
    let redirect = false;
    let salesArr = currentUserRoles?.filter((x) => x !== "pt" && x !== "pa");
    let purArr = currentUserRoles?.filter((x) => x === "pt" || x === "pa");

    let bookingStatus = props?.history?.location?.pathname?.includes(
      "inventory-booking-status"
    );

    if (bookingStatus) {
      redirect = false;
    } else {
      if (salesArr.length > 0) {
        redirect = false;
      } else if (purArr.length > 0) {
        redirect = true;
      }
    }

    return redirect;
  };

  const getConfigurationDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        setEnableDashboard(data?.configuration_details?.enable_dashboard);
      },
      "GET"
    );
  };

  useEffect(() => {
    checkRedirect() && navigate("/purchase/transactions");
  }, []);

  useEffect(() => {
    getConfigurationDetails();
  }, []);

  return (
    <React.Fragment>
      <Tag className="menu-toggle" color="#108ee9" onClick={handleMenuToggle}>
        {activeModule === "sales" ? "P" : "S"}
      </Tag>

      <Menu
        theme={theme}
        mode="horizontal"
        defaultSelectedKeys={checkActive()}
        selectedKeys={checkActive()}
        style={{
          float: "right",
          textAlign: "right",
          width: "calc(100% - 168px)",
          display: "flex",
          justifyContent: "right",
        }}
        onClick={({ key }) => {
          switch (key) {
            case "logout":
              localStorage.clear();
              window.location.hash = "";
              window.location.reload();
              break;
            default:
              break;
          }
        }}
        className="web-menu"
        items={activeModule === "sales" ? menuItemsObj : menuItemsPurchaseObj}
      />

      <Dropdown
        menu={{
          theme: { theme },
          items: activeModule === "sales" ? menuItemsObj : menuItemsPurchaseObj,
          onClick: ({ key }) => {
            switch (key) {
              case "logout":
                localStorage.clear();
                window.location.hash = "";
                window.location.reload();
                break;
              default:
                break;
            }
          },
        }}
        // overlay={
        //   <Menu
        //     theme={theme}
        //     defaultSelectedKeys={checkActive()}
        //     selectedKeys={checkActive()}
        //     onClick={({ key }) => {
        //       switch (key) {
        //         case "logout":
        //           localStorage.clear();
        //           window.location.hash = "";
        //           window.location.reload();
        //           break;
        //         default:
        //           break;
        //       }
        //     }}
        //     items={activeModule === "sales" ? menuItemsObj : menuItemsPurchaseObj}
        //   />
        // }
        placement="bottomRight"
        trigger={["click"]}
        overlayClassName="mobile-menu-dropdown"
      >
        <Button
          className="mobile-menu"
          icon={<MenuOutlined />}
          style={{ display: "none" }}
        />
      </Dropdown>
    </React.Fragment>
  );
};

export default AppMainMenu;
