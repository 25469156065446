import React from "react";
import { Tabs, Button, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import CommonListBase from "../../base/common-list-base";
import PageTitle from "../../components/common/page-title";
import SalesMonthlyChart from "../../components/dashboard/sales-monthly-chart";
import SalesCityChart from "../../components/dashboard/sales-city-chart";
import SalesEstimateChart from "../../components/dashboard/sales-estimate-chart";
import SalesEmployeeChart from "../../components/dashboard/sales-employee-chart";
import SalesMonthlyList from "../../components/dashboard/sales-monthly-list";
import SalesCityList from "../../components/dashboard/sales-city-list";
import SalesEstimateList from "../../components/dashboard/sales-estimate-list";
import SalesEmployeeList from "../../components/dashboard/sales-employee-list";

class SalesDashboard extends CommonListBase {
  state = {
    active: "month",
    displayYear: "Current FY 24-25",
    key: "current_finyear",
  };

  tabChange = (key) => {
    this.setState({ active: key });
  };

  handleDetails = (month, record) => {
    const { active, key } = this.state;

    let req = "/dashboard/sales-details/" + active + "/" + key + "/" + month;

    if (record) {
      if (active === "city") {
        req =
          req +
          "/" +
          record?.state_name +
          "|" +
          record?.district_name +
          "|" +
          record?.type;
      }
      if (active === "payoutestimate") {
        req = req + "/" + record?.payout_estimate_id;
      }
      if (active === "employee") {
        req = req + "/" + record?.employee_id;
      }
    }

    window.open("#" + req, "_blank");
  };

  handleMenuClick = ({ key }) => {
    if (key === "current_finyear") {
      this.setState({ displayYear: "Current FY 24-25", key });
    }
    if (key === "last_finyear") {
      this.setState({ displayYear: "Previous FY 23-24", key });
    }
  };

  render() {
    const { active, displayYear, key } = this.state;
    const { TabPane } = Tabs;

    return (
      <div>
        <PageTitle titleText="Sales Dashboard" />

        <div style={{ float: "right", marginTop: "-64px" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "current_finyear",
                  label: "Current FY 24-25",
                },
                {
                  key: "last_finyear",
                  label: "Previous FY 23-24",
                },
              ],
              onClick: this.handleMenuClick,
            }}
            placement="bottomRight"
            trigger={["click"]}
          >
            <Button>
              {displayYear}
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>

        <div style={{ height: "480px", marginBottom: "42px" }}>
          {active === "month" && <SalesMonthlyChart key={key} year={key} />}
          {active === "city" && <SalesCityChart key={key} year={key} />}
          {active === "payoutestimate" && (
            <SalesEstimateChart key={key} year={key} />
          )}
          {active === "employee" && <SalesEmployeeChart key={key} year={key} />}
        </div>

        <Tabs defaultActiveKey={active} onChange={this.tabChange}>
          <TabPane tab="Monthly Sales" key="month">
            <SalesMonthlyList
              key={key}
              year={key}
              handleDetails={this.handleDetails}
            />
          </TabPane>

          <TabPane tab="City Wise" key="city">
            <SalesCityList
              key={key}
              year={key}
              handleDetails={this.handleDetails}
            />
          </TabPane>

          <TabPane tab="Payout Estimate Wise" key="payoutestimate">
            <SalesEstimateList
              key={key}
              year={key}
              handleDetails={this.handleDetails}
            />
          </TabPane>

          <TabPane tab="Employee Wise" key="employee">
            <SalesEmployeeList
              key={key}
              year={key}
              handleDetails={this.handleDetails}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default SalesDashboard;
