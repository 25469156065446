import React from "react";
import {
  Row,
  Col,
  Table,
  Descriptions,
  Card,
  Typography,
  Form,
  Button,
  InputNumber,
  Drawer,
  Menu,
  Dropdown,
  Radio,
  Switch,
} from "antd";
import {
  DeleteOutlined,
  EllipsisOutlined,
  FileAddOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { currencyFormatter } from "../../components/common/common-formatter";
import PageTitleDetails from "../common/page-title-detail";
import ListFilters from "../../components/common/list-filters";
import PlanSelectInventory from "./plan-select-inventories";
import PhotoGallery from "../../components/common/photo-gallery";
import PlanCustomPhotos from "./plan-custom-photos";
import PlanCampaignInventoryBase from "../../base/plan-campaign-inventory-base";
import PrintingMounting from "./printing-mounting";
import { icon } from "leaflet";

class PlanInventories extends PlanCampaignInventoryBase {
  state = {
    results: "",
    resultsDefault: "",
    selectedRowKeys: [],
    selectedRows: [],
    isDrawer: false,
    columns: [],
    filterColumns: [],
    inventory_details: [],
    inventory_details_default: [],
    inventory_details_default_filter: [],
    displayValues: "",
    displayValues_default: "",
    currentValues: "",
    currentValues_default: "",
    selectedRadio: "discounting",
    isAdvanced: false,
    page: "plan",
    inventoryType: "hainventory",
    auto_adjust_site_start_dates:
      this.props?.results?.auto_adjust_site_start_dates,
    screen: "plan-inventories",
    isChanged: false,
    isSaveLoading: false,
    is_rotational: this.props?.results?.is_rotational,
  };

  componentDidMount() {
    this.setData();
  }

  render() {
    const {
      displayValues,
      columns,
      selectedRowKeys,
      results,
      isDrawer,
      filterColumns,
      inventory_details,
      selectedRadio,
      isAdvanced,
      isShowPhotos,
      photos,
      isCustomPhoto,
      inventory_details_default_filter,
      screen,
      photosTitle,
      isChanged,
      isSaveLoading,
      is_rotational,
    } = this.state;
    const { Title } = Typography;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const layout2 = {
      wrapperCol: { span: 24 },
    };

    const layout3 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };

    const layout4 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    return (
      <div className="plan-campaign-inv">
        <PageTitleDetails
          titleText={"Plan - Sales Negotiation - " + results.display_name}
          isBackBtn="true"
          handleBack={() => this.props.handleBack(isChanged)}
        />

        <Button
          type="primary"
          className="page-title-custom-btn icon-btn"
          onClick={this.handleUpdates}
          loading={isSaveLoading}
          icon={<SaveOutlined />}
        >
          Save Plan
        </Button>

        <div
          className="page-title-custom-btn icon-btn"
          style={{ right: "128px", top: "6px" }}
        >
          <Switch
            defaultChecked={is_rotational}
            onChange={this.handleRotational}
            size="small"
          ></Switch>
          <label style={{ marginLeft: "8px" }}>Rotational</label>
        </div>

        <Row gutter={16} className="plan-dtl-boxes">
          <Col span={12} xs={24} xl={12}>
            <Card
              title={<Title level={5}>Inventories</Title>}
              style={{ height: "100%" }}
              extra={
                <Button
                  style={{ margin: "-8px" }}
                  onClick={this.handleAdvanced}
                >
                  Printing & Mounting Cost
                </Button>
              }
            >
              <Form
                name="plan_add"
                {...layout}
                ref={this.formRef}
                onFinish={this.onFinish}
                className="plan_inventories"
                onValuesChange={this.onFinish}
              >
                <Row>
                  <Col span="24" offset="0">
                    <Radio.Group
                      value={selectedRadio}
                      onChange={this.onChangeRadio}
                      style={{ marginBottom: "24px" }}
                    >
                      <Radio value="discounting">Discounting</Radio>
                      <Radio value="pricing">Pricing</Radio>
                      <Radio value="rateSQFT">Rate / SQFT / Month</Radio>
                      <Radio value="rateUnit">Rate / Unit / Month</Radio>
                    </Radio.Group>
                  </Col>
                </Row>

                {selectedRadio === "discounting" && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="discounting_per"
                        label="Discounting"
                        {...layout3}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0}
                          placeholder="%"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {selectedRadio === "pricing" && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="discounting_rate"
                        label="Pricing"
                        {...layout3}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0}
                          placeholder="lumpsum Rate"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {selectedRadio === "rateSQFT" && (
                  <Row>
                    <Col span={24}>
                      <Row gutter={8}>
                        <Col span={8} xs={7} xl={8}>
                          <Form.Item
                            name="discounting_rate_all"
                            label="Rate / SQFT"
                            {...layout4}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="ALL"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={2} xs={2} xl={2}>
                          <div
                            style={{
                              display: "block",
                              textAlign: "center",
                              marginTop: "5px",
                            }}
                            className="without-label-text"
                          >
                            OR
                          </div>
                        </Col>

                        <Col span={4} xs={5} xl={4}>
                          <Form.Item
                            name="discounting_rate_fl"
                            {...layout2}
                            className="without-label"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="FL"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4} xs={5} xl={4}>
                          <Form.Item
                            name="discounting_rate_bl"
                            {...layout2}
                            className="without-label"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="BL"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4} xs={5} xl={4}>
                          <Form.Item
                            name="discounting_rate_nl"
                            {...layout2}
                            className="without-label"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="NL"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {selectedRadio === "rateUnit" && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="rate_per_unit"
                        label="Rate / Unit"
                        {...layout3}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0}
                          placeholder="Rate / Unit"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col span={12} xs={11} xl={12}>
                    <Form.Item name="print_fl_rate" label="Printing">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={2} xs={2} xl={2}>
                    <div
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                      className="without-label-text"
                    >
                      &amp;
                    </div>
                  </Col>

                  <Col span={8} xs={11} xl={8}>
                    <Form.Item
                      name="print_bl_rate"
                      {...layout2}
                      className="without-label"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12} xs={11} xl={12}>
                    <Form.Item name="install_fl_rate" label="Mounting">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={2} xs={2} xl={2}>
                    <div
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                      className="without-label-text"
                    >
                      &amp;
                    </div>
                  </Col>

                  <Col span={8} xs={11} xl={8}>
                    <Form.Item
                      name="install_bl_rate"
                      {...layout2}
                      className="without-label"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>

          <Col span={6} xs={24} xl={6}>
            <Card
              title={<Title level={5}>Statistics</Title>}
              bodyStyle={{ paddingBottom: "0px" }}
              style={{ height: "100%" }}
            >
              <Descriptions column={1}>
                <Descriptions.Item label="HA Markup" className="ant-text-right">
                  <b
                    className={
                      displayValues.base_rate_diff > 0
                        ? "ant-color-green"
                        : "ant-color-red"
                    }
                  >
                    {currencyFormatter(
                      parseInt(displayValues.base_rate_diff_amt)
                    )}{" "}
                    ({displayValues.base_rate_diff || 0}%)
                  </b>
                </Descriptions.Item>

                <Descriptions.Item label="TA Markup" className="ant-text-right">
                  <b
                    className={
                      displayValues?.ta_base_rate_diff > 0
                        ? "ant-color-green"
                        : "ant-color-red"
                    }
                  >
                    {currencyFormatter(
                      parseInt(displayValues?.ta_base_rate_diff_amt)
                    )}{" "}
                    ({displayValues?.ta_base_rate_diff || 0}%)
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Expense Difference"
                  className="ant-text-right"
                >
                  <b>{}</b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Total SQFT"
                  className="ant-text-right"
                >
                  <b>{displayValues?.total_sqft}</b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Price / SQFT"
                  className="ant-text-right"
                >
                  <b>{displayValues.rate_per_sqft}</b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Total Inventories"
                  className="ant-text-right"
                >
                  <b>
                    {inventory_details_default_filter &&
                      inventory_details_default_filter.length}
                  </b>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>

          <Col span={6} xs={24} xl={6}>
            <Card
              title={<Title level={5}>Summary</Title>}
              bodyStyle={{ paddingBottom: "0px" }}
            >
              <Descriptions column={1}>
                <Descriptions.Item
                  label="Display Cost"
                  className="ant-text-right"
                >
                  <b>
                    {currencyFormatter(
                      parseInt(displayValues.total_display_cost)
                    )}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Printing Cost"
                  className="ant-text-right"
                >
                  <b>
                    {currencyFormatter(
                      parseInt(displayValues.total_printing_cost)
                    )}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Installation Cost"
                  className="ant-text-right"
                >
                  <b>
                    {currencyFormatter(
                      parseInt(displayValues.total_installing_cost)
                    )}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Total Without Tax"
                  className="ant-text-right"
                >
                  <b>
                    {currencyFormatter(
                      parseInt(displayValues.total_without_tax)
                    )}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label={"GST (" + displayValues.gst_per + "%)"}
                  className="ant-text-right"
                >
                  <b>{currencyFormatter(parseInt(displayValues.gst_amount))}</b>
                </Descriptions.Item>

                <Descriptions.Item
                  label={<b>Grand Total</b>}
                  className="ant-text-right"
                  style={{
                    borderTop: "solid 1px #f0f0f0",
                    paddingTop: "12px",
                  }}
                >
                  <b>
                    {currencyFormatter(parseInt(displayValues.total_amount))}
                  </b>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>
            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                float: "right",
                position: "relative",
                marginTop: "-48px",
                marginRight: "48px",
              }}
              className="plan-inv-list-actions"
            >
              <Switch
                size="small"
                onChange={this.handleAdjustPlanDatesToggle}
                checked={this.state.auto_adjust_site_start_dates}
              ></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                Auto Adjust Start Dates
              </label>

              <Dropdown
                menu={{
                  items: [
                    {
                      key: "add",
                      label: "Add HA Inventory",
                      icon: <FileAddOutlined />,
                    },
                    {
                      key: "add-ta",
                      label: "Add TA inventory",
                      icon: <FileAddOutlined />,
                    },
                    ...(selectedRowKeys.length > 0
                      ? [
                          {
                            key: "delete",
                            label: "Delete",
                            icon: <DeleteOutlined />,
                          },
                        ]
                      : []),
                  ],
                  onClick: this.handleMenuClick,
                }}
                placement="bottomRight"
                trigger={["click"]}
              >
                <Button style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                  <EllipsisOutlined />
                </Button>
              </Dropdown>
            </div>

            <Table
              columns={columns}
              rowKey={(record) => record.uuid}
              dataSource={inventory_details}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              rowSelection={rowSelection}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => this.getMultiDetails(record),
                rowExpandable: (record) =>
                  record.ha_base_inventories &&
                  record.ha_base_inventories.length > 0,
              }}
              rowClassName={(record) => this.setFilter(record)}
            />
          </Col>
        </Row>

        <Drawer
          title={!isAdvanced ? "Add Inventories" : "Printing & Mounting Cost"}
          placement="right"
          open={isDrawer}
          onClose={!isAdvanced ? this.handleDrawer : this.handleAdvanced}
          width={!isAdvanced ? "88%" : "88%"}
          key={isDrawer}
        >
          {!isAdvanced && (
            <PlanSelectInventory
              key={isDrawer}
              addSelectedInventory={this.calAddInventory}
              {...this.state}
            />
          )}

          {isAdvanced && (
            <PrintingMounting
              key={isDrawer}
              {...this.state}
              handleAdvancedPrinting={this.handleAdvancedPrinting}
            />

            // <PlanAdvanced
            //   key={isDrawer}
            //   {...this.state}
            //   handleAdvancedPrinting={this.handleAdvancedPrinting}
            // />
          )}
        </Drawer>

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
          />
        )}

        {isCustomPhoto && (
          <Drawer
            title={"Custom Photo"}
            placement="right"
            open={isCustomPhoto}
            onClose={this.handleCustomPhoto}
            width="88%"
            key={isCustomPhoto}
          >
            <PlanCustomPhotos key={isCustomPhoto} {...this.state} />
          </Drawer>
        )}
      </div>
    );
  }
}

export default PlanInventories;
